





















import { Component, Model, Mixins } from 'vue-property-decorator';
import Id from '@/features/ui/mixins/id';
import { StringProp, BooleanProp, OptionalProp } from '@/util/prop-decorators';

@Component({ inheritAttrs: false })
export default class TextInput extends Mixins(Id) {
  @Model('update')
  private readonly value!: unknown;
  @OptionalProp('')
  private readonly emptyValue!: unknown;
  @BooleanProp()
  private readonly multiline!: boolean;
  @BooleanProp()
  private readonly password!: boolean;
  @BooleanProp()
  private readonly error!: boolean;
  @StringProp()
  private readonly errorMessage!: string;
  @StringProp()
  private readonly label!: string;
  @StringProp()
  private readonly placeholder?: string;
  @StringProp()
  private readonly tooltip?: string;
  @StringProp('?')
  private readonly tooltipLabel!: string;

  private get component(): string {
    return this.multiline ? 'textarea' : 'input';
  }

  private get type(): string | undefined {
    return this.password ? 'password' : this.multiline ? undefined : 'text';
  }

  private onInput({ target }: { target: HTMLInputElement }): void {
    const value = target.value.trim();

    this.$emit('update', value === '' ? this.emptyValue : value);
  }
}
