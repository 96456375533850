















import { BooleanProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import UiModal from './Modal.global.vue';
import eventBus from '@/util/event-bus';

@Component({
  data(this: Wizard) {
    return { visible: this.initiallyVisible };
  },
})
export default class Wizard extends Vue {
  @StringProp()
  private readonly id?: string;

  @StringProp()
  private readonly title!: string;

  @BooleanProp()
  private readonly initiallyVisible!: boolean;

  private visible!: boolean;

  public readonly $refs!: { modal: UiModal };

  public show(): Promise<void> {
    this.visible = true;
    this.$emit('show');

    return this.$refs.modal?.shown() ?? Promise.resolve();
  }

  public hide(): Promise<void> {
    this.visible = false;
    this.$emit('hide');

    return this.$refs.modal?.hidden() ?? Promise.resolve();
  }

  private mounted(): void {
    eventBus.$on('uiWizard:show', (id: string) => this.id === id && this.show());
    eventBus.$on('uiWizard:hide', (id: string) => this.id === id && this.hide());
  }
}
