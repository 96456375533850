












import { NumberProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DeviceRolePanel extends Vue {
  @StringProp(true)
  private readonly headline!: string;
  @StringProp(true)
  private readonly backgroundImage!: string;
  @NumberProp(true)
  private readonly totalCount!: number;
  @NumberProp(true)
  private readonly onlineCount!: number;

  private get backgroundImageStyle(): string {
    return `background-image: url(${this.backgroundImage});`;
  }
}
