


















import { Component, Model, Mixins } from 'vue-property-decorator';
import pencil from '@/features/app-admin/assets/pencil.svg';
import Id from '@/features/ui/mixins/id';
import { OptionalProp } from '../../util/prop-decorators';

@Component({ inheritAttrs: false })
export default class EditableValue extends Mixins(Id) {
  @Model('update')
  private readonly value!: unknown;

  @OptionalProp('text')
  private type!: string;

  private pencil = pencil;

  private editing = false;

  private onChange({ target }: { target: HTMLInputElement }): void {
    const value = target.value.trim();
    this.$emit('update', value);
    this.editing = false;
  }

  private onBlur(): void {
    if (this.editing) {
      this.editing = false;
    }
  }

  private onEdit(): void {
    this.editing = !this.editing;
  }
}
