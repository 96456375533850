




import { EnumProp, FunctionProp, OptionalProp } from '@/util/prop-decorators';
import * as echarts from 'echarts';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import ChartSetsMixin from '../ChartSets.vue';
import { ChartAggregationInterval } from '../model';
import { Chart, ChartOptions, CHART_SERIES_COLOR_PALLETE, SingleTooltipFormatterParams } from './model';

@Component
export default class EChartsBarChart extends Mixins(ChartSetsMixin) {
  @FunctionProp()
  private formatValue?: (value: string | number | Date | Moment, name: string) => string;

  @EnumProp(...Object.values(ChartAggregationInterval))
  private aggregationInterval!: ChartAggregationInterval;

  @OptionalProp()
  private readonly chartOptions?: ChartOptions;

  @FunctionProp()
  private yAxisLabelFormatter?: (value: string | number, index: number) => string;

  public readonly $refs!: { barChart: HTMLDivElement };

  private chart!: Chart;

  private zoomIntervalStartValue!: string;

  private zoomIntervalEndValue!: string;

  private get options(): ChartOptions {
    return {
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: this.yAxisLabelFormatter,
        },
      },
      scale: true,
      xAxis: {
        type: 'time',
        axisLabel: {
          formatter: this.xAxisLabelFormatter,
          hideOverlap: true,
        },
      },
      series: this.sets.map(({ label, name, points }, index) => ({
        name: label,
        type: 'bar',
        color: CHART_SERIES_COLOR_PALLETE[index],
        barWidth: '50%',
        showBackground: this.sets.length == 1,
        backgroundStyle: {
          color: `rgba(180, 180, 180, 0.2)`,
        },
        encode: { x: 'date', y: 'value' },
        dimensions: ['label', 'name', 'date', 'value'],
        data: points.map(({ x, y }) => [label, name, x as string, y as string]),
      })),
      legend: { show: true, data: this.sets.map(({ label }) => label), top: 0 },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tooltip: {
        trigger: 'item',
        position: 'top',
        backgroundColor: 'transparent',
        shadowColor: 'transparent',
        borderColor: 'transparent',
        extraCssText: 'width:auto; white-space:pre-wrap;',
        formatter: (params: SingleTooltipFormatterParams): string => {
          const htmlValues = [];
          const { seriesIndex, dataIndex, seriesName } = params;

          const { currentValue, previousValue } =
            this.getCurrentAndPreviousSetPoint({ seriesIndex: Number(seriesIndex), dataIndex }) ?? {};

          if (currentValue && previousValue) {
            htmlValues.push(`<strong>${seriesName}:</strong> ${currentValue} (${previousValue})`);
          } else if (currentValue) {
            htmlValues.push(`<strong>${seriesName}:</strong> ${currentValue}`);
          }

          return htmlValues.join('</br>');
        },
      },
      grid: {
        left: '5%',
        right: '5%',
        containLabel: true,
      },
      dataZoom: [
        {
          type: 'inside',
          minValueSpan: 1000 * 60 * 60 * 24 * 1,
        },
        {},
      ],
      ...this.chartOptions,
    };
  }

  private resizeChart(): void {
    this.chart.resize({
      width: this.$refs.barChart.parentElement?.clientWidth,
      height: 450,
    });
  }

  private created(): void {
    window.addEventListener('resize', this.resizeChart);
  }

  private mounted(): void {
    this.chart = echarts.init(this.$refs.barChart, undefined, {
      locale: 'de',
      width: this.$refs.barChart.parentElement?.clientWidth,
      height: 450,
      renderer: 'svg',
    });
    this.chart.setOption<ChartOptions>(this.options, { notMerge: true, lazyUpdate: true });

    this.chart.on('datazoom', () => {
      const options = this.chart.getOption();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.zoomIntervalStartValue = options?.dataZoom[0].startValue;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.zoomIntervalEndValue = options?.dataZoom[0].endValue;
    });
  }

  private destroyed(): void {
    window.removeEventListener('resize', this.resizeChart);
  }

  private get timeDifferenceOfPointsInDays(): number {
    const setPoints = this.sets.flatMap(({ points }) => points);

    const timeOfSetPoints = setPoints.map(({ x }) => x as ISODate);

    const sortedTimeOfSetPoints = sortBy(timeOfSetPoints);

    if (sortedTimeOfSetPoints.length === 0) {
      return 0;
    }

    const firstPoint = DateTime.fromISO(sortedTimeOfSetPoints[0]);

    const lastPoint = DateTime.fromISO(sortedTimeOfSetPoints[sortedTimeOfSetPoints.length - 1]);

    return Math.abs(firstPoint.diff(lastPoint, ['days']).days);
  }

  private get xAxisTimeFormat(): string {
    switch (true) {
      case this.timeDifferenceOfPointsInDays < 2:
        return 'dd LLL HH:mm';
      case this.timeDifferenceOfPointsInDays < 14:
        return 'dd LLL';
      case this.timeDifferenceOfPointsInDays < 30:
        return "K'W' W";
      case this.timeDifferenceOfPointsInDays < 365:
        return 'dd.LL';
      case this.timeDifferenceOfPointsInDays >= 365:
        return 'yyyy LLL';
      default:
        return 'x';
    }
  }

  private zoomIntervalDifferenceInDays(startValue: string, endValue: string): number {
    const start = DateTime.fromJSDate(new Date(startValue));

    const end = DateTime.fromJSDate(new Date(endValue));

    return Math.abs(start.diff(end, ['days']).days);
  }

  private xAxisLabelFormatter(value: number): string {
    const time = DateTime.fromJSDate(new Date(value));

    if (time.day === 1) {
      return time.toFormat('LLL');
    }

    const zoomIntervalDifference = this.zoomIntervalDifferenceInDays(
      this.zoomIntervalStartValue,
      this.zoomIntervalEndValue,
    );

    switch (true) {
      case zoomIntervalDifference < 5:
        return time.toFormat('dd LLL HH:mm');
      case zoomIntervalDifference < 14:
        return time.toFormat('dd LLL');
      case zoomIntervalDifference < 30:
        return time.toFormat("K'W' W");
      case zoomIntervalDifference < 365:
        return time.toFormat('dd.LL');
      case zoomIntervalDifference >= 365:
        return time.toFormat('yyyy LLL');
      default:
        return time.toFormat(this.xAxisTimeFormat);
    }
  }

  private getCurrentAndPreviousSetPoint({
    seriesIndex,
    dataIndex,
  }: {
    seriesIndex: number;
    dataIndex: number;
  }): { currentValue: string | number; previousValue: string | number } | undefined {
    if (seriesIndex === undefined) {
      return undefined;
    }

    const { name, points } = this.sets[seriesIndex];
    const point = points[dataIndex];
    const previousPoint = points[dataIndex + 1];

    if (!previousPoint) {
      return {
        currentValue: this.formatValue ? this.formatValue(point.y ?? 0, name) : Number(point.y),
        previousValue: 0,
      };
    }

    const diff = Number(point.y) - Number(previousPoint.y);
    const value = this.formatValue ? this.formatValue(point.y ?? 0, name) : String(diff);

    const formattedValue = this.formatValue ? this.formatValue(diff, name) : String(diff);
    const prefix = formattedValue.startsWith('-') ? '' : '+';

    return { currentValue: value, previousValue: prefix + formattedValue };
  }

  @Watch('sets')
  private onChartDataChange(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.options.dataZoom[0].startValue = this.zoomIntervalStartValue;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.options.dataZoom[0].endValue = this.zoomIntervalEndValue;
    this.chart.setOption<ChartOptions>(this.options, { notMerge: true, lazyUpdate: true });
  }
}
