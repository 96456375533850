










import { Option } from '@/features/ui/inputs/model';
import { ArrayProp } from '@/util/prop-decorators';
import { difference, startCase } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { Column } from './model';

@Component({ model: { event: 'update', prop: 'hiddenColumns' } })
export default class TableColumnSelection extends Vue {
  @ArrayProp(() => [])
  private readonly columns!: Column[];

  @ArrayProp(() => [])
  private readonly hiddenColumns!: string[];

  private get columnOptions(): Option[] {
    return this.columns
      .filter(({ hideable }) => hideable)
      .map(({ name, label }) => ({ value: name, label: label ?? startCase(name) }));
  }

  private get hideableColumns(): string[] {
    return this.columns.filter(({ hideable }) => hideable).map(({ name }) => name);
  }

  private get shownColumns(): string[] {
    return difference(this.hideableColumns, this.hiddenColumns);
  }

  private set shownColumns(names: string[]) {
    this.$emit('update', difference(this.hideableColumns, names));
  }
}
