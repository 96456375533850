




import { EnumProp } from '@/util/prop-decorators';
import { Component as ComponentType } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import EChartsBarChart from './echarts/EChartsBarChart.vue';
import EchartsStepChart from './echarts/EchartsStepChart.vue';
import EChartsLineChart from './echarts/EChartsLineChart.vue';
import { ChartStyle } from './model';

@Component
export default class DynamicChart extends Vue {
  @EnumProp('line', 'bar', 'step')
  private readonly chartStyle!: ChartStyle;

  private get component(): ComponentType {
    const componentMap = { line: EChartsLineChart, bar: EChartsBarChart, step: EchartsStepChart };

    return componentMap[this.chartStyle];
  }
}
