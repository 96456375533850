





import { ArrayProp, BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import Node from './Node.vue';

@Component({ components: { Node } })
export default class NodeList<T = unknown> extends Vue {
  @ArrayProp(true)
  private readonly nodes!: T[];

  @BooleanProp()
  private readonly indent!: boolean;
}
