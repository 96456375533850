










import { BooleanProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PanelHeader extends Vue {
  @StringProp('')
  private readonly title!: string;

  @BooleanProp()
  private readonly hideTitle!: boolean;
}
