




import { Component, Model, Vue } from 'vue-property-decorator';
import { Input } from './model';

// File inputs are read only

@Component
export default class InputFile extends Vue implements Input {
  @Model('update')
  private value?: File | undefined;

  public pristine = true;

  public setValue(event: Event): void {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }
    this.$emit('update', event.target.files?.[0]);
  }

  public get normalizedValue(): File | undefined {
    return this.value;
  }

  public get empty(): boolean {
    return this.normalizedValue === undefined;
  }
}
