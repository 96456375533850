import { EnumProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

export enum StateColors {
  GREEN = 'GREEN',
  GRAY = 'GRAY',
  RED = 'RED',
  YELLOW = 'YELLOW',
  LIGHT_GRAY = 'LIGHT-GRAY',
}

@Component
export class StatePropertyMixin extends Vue {
  @EnumProp(...Object.values(StateColors))
  protected readonly state!: StateColors;
}
