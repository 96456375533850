





















import { BooleanProp, StringProp } from '@/util/prop-decorators';
import { Component, Model, Vue } from 'vue-property-decorator';

@Component({
  data(this: CollapsiblePanel) {
    return { localCollapsed: !this.collapseDisabled && this.collapsed };
  },
})
export default class CollapsiblePanel extends Vue {
  @Model('update', { type: Boolean, default: true })
  private readonly collapsed!: boolean;

  @BooleanProp()
  private readonly controlled!: boolean;

  @BooleanProp()
  private readonly collapseDisabled!: boolean;

  @StringProp('')
  private readonly title!: string;

  @BooleanProp()
  private readonly hideTitle!: boolean;

  private localCollapsed!: boolean;

  private get realCollapsed(): boolean {
    return !this.collapseDisabled && (this.controlled ? this.collapsed : this.localCollapsed);
  }

  private set realCollapsed(value: boolean) {
    if (this.controlled) {
      this.$emit('update', value);
    } else {
      this.localCollapsed = value;
    }
  }
}
