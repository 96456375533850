















import { Component, Model, Mixins } from 'vue-property-decorator';
import { StringProp, EnumProp, BooleanProp } from '@/util/prop-decorators';
import Id from '@/features/ui/mixins/id';

@Component
export default class RectangleIcon extends Mixins(Id) {
  @Model('update', { type: String, default: '' })
  private value!: string;
  @StringProp()
  private iconValue?: string;
  @BooleanProp()
  private iconType?: boolean;
  @EnumProp('md', 'sm', 'lg')
  private size!: string;
  @EnumProp('blue', 'red')
  private borderColor!: string;

  private get labelSize(): string {
    return `label-${this.size}`;
  }
}
