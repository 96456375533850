





import { BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ToggleExpand extends Vue {
  @BooleanProp()
  private readonly expanded!: boolean;

  @BooleanProp()
  private readonly right!: boolean;
}
