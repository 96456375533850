
import { EnumProp, FunctionProp, OptionalProp } from '@/util/prop-decorators';
import { ChartData, ChartOptions, ChartPoint } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Moment } from 'moment';
import { Line } from 'vue-chartjs';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import ChartSetsMixin from '../ChartSets.vue';
import { ChartAggregationInterval } from '../model';

@Component({ extends: Line })
export default class ChartJSLineChart extends Mixins(ChartSetsMixin, Line) {
  @FunctionProp()
  private formatValue?: (value: string | number | Date | Moment, name: string) => string;

  @EnumProp(...Object.values(ChartAggregationInterval))
  private aggregationInterval?: ChartAggregationInterval;

  @OptionalProp()
  private readonly chartData?: ChartData;

  @OptionalProp()
  private readonly chartOptions?: ChartOptions;

  private get defaultChartData(): ChartData {
    return {
      datasets: this.sets.map(({ label, points }) => ({
        backgroundColor: 'transparent',
        borderColor: '#e60000',
        pointBackgroundColor: '#e60000',
        pointHoverBackgroundColor: '#e60000',
        data: points,
        pointHoverRadius: 3.5,
        pointRadius: 3,
        borderWidth: 2,
        label,
      })),
    };
  }

  private get defaultChartOptions(): ChartOptions {
    const dateFormatter = new Intl.DateTimeFormat('default', { weekday: 'short', day: '2-digit', month: '2-digit' });

    return {
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      plugins: {
        datalabels: {
          color: '#969595',
          display: ({ active }) => active,
          labels: {
            value: {
              color: '#969595',
              textStrokeColor: 'white',
              textStrokeWidth: 4,
              align: 'top',
              anchor: 'end',
              formatter: (point: ChartPoint, context): string => {
                const { label, name } = this.sets[context.datasetIndex];

                const value = this.formatValue && point.y !== undefined ? this.formatValue(point.y, name) : '';

                return `${label} ${value}`;
              },
            },
            diff: {
              textStrokeColor: 'white',
              textStrokeWidth: 4,
              align: 'bottom',
              anchor: 'start',
              offset: 6,
              formatter: (point: ChartPoint, context): string => {
                const { name, points } = this.sets[context.datasetIndex];
                const previousPoint = points[context.dataIndex + 1];
                if (!previousPoint) {
                  return '';
                }

                const diff = Number(point.y) - Number(previousPoint.y);
                if (diff === 0) {
                  return '';
                }

                const formattedValue = this.formatValue ? this.formatValue(diff, name) : String(diff);
                const prefix = formattedValue.startsWith('-') ? '' : '+';

                return prefix + formattedValue;
              },
            },
          },
        },
      },
      legend: {
        display: false,
      },
      layout: {
        padding: {
          top: 30,
          right: 30,
          left: 30,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [{ display: false }, { display: false }],
        xAxes: [
          {
            type: 'time',
            distribution: 'linear',
            time: {
              round: this.aggregationInterval,
              displayFormats: {
                minute: 'YYYY-MM-DD HH:MM',
                hour: 'YYYY-MM-DD HH:MM',
                day: 'x',
                week: 'x',
                year: 'x',
              },
            },
            ticks: {
              padding: 20,
              labelOffset: 0,
              fontColor: '#969595',
              fontSize: 11,
              callback: (value) => {
                try {
                  return dateFormatter.format(new Date(Number(value)));
                } catch {
                  return String(value);
                }
              },
            },
            scaleLabel: {
              display: false,
            },
            gridLines: {
              drawBorder: false,
            },
          },
        ],
      },
      tooltips: {
        enabled: false,
      },
    };
  }

  private mounted(): void {
    this.addPlugin([ChartDataLabels]);
    this.renderChart(this.chartData ?? this.defaultChartData, this.chartOptions ?? this.defaultChartOptions);
  }

  @Watch('sets')
  private onChartDataChange(): void {
    this.renderChart(this.chartData ?? this.defaultChartData, this.chartOptions ?? this.defaultChartOptions);
  }
}
