




import { ArrayProp, EnumProp, FunctionProp, OptionalProp } from '@/util/prop-decorators';
import * as echarts from 'echarts';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import ChartSetsMixin from '../ChartSets.vue';
import { ChartAggregationInterval } from '../model';
import { Chart, ChartOptions } from './model';

@Component
export default class EChartsStepChart extends Mixins(ChartSetsMixin) {
  @FunctionProp()
  private formatValue?: (value: string | number | Date | Moment, name: string) => string;

  @EnumProp(...Object.values(ChartAggregationInterval))
  private aggregationInterval!: ChartAggregationInterval;

  @OptionalProp()
  private readonly chartOptions?: ChartOptions;

  @ArrayProp(() => [])
  private readonly yAxisDimensions?: Array<string | number>;

  @FunctionProp()
  private yAxisLabelFormatter?: (value: string | number, index: number) => string;

  public readonly $refs!: { stepChart: HTMLDivElement };

  private chart!: Chart;

  private zoomIntervalStartValue!: string;

  private zoomIntervalEndValue!: string;

  private get options(): ChartOptions {
    return {
      title: {
        text: 'Step line',
      },
      yAxis: {
        type: 'category',
        axisLabel: {
          formatter: this.yAxisLabelFormatter,
        },
        data: this.yAxisDimensions,
        axisPointer: {
          snap: true,
          type: 'line',
        },
      },
      scale: false,
      xAxis: {
        type: 'time',
        axisLabel: {
          formatter: this.xAxisLabelFormatter,
          hideOverlap: true,
        },
        tooltip: {
          show: true,
        },
      },
      series: this.sets.map(({ label, name, points }) => ({
        name: label,
        type: 'line',
        step: 'start',
        encode: { x: 'date', y: 'value' },
        dimensions: ['label', 'name', 'date', 'value'],
        data: points.map(({ x, y }) => [label, name, x as string, y as string]),
        showSymbol: false,
      })),
      legend: { show: true, data: this.sets.map(({ label }) => label), top: 0 },
      grid: {
        left: '5%',
        right: '5%',
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          axis: 'x',
        },
        formatter: (): string => {
          return '';
        },
      },
      dataZoom: [
        {
          type: 'inside',
          minValueSpan: 1000 * 60 * 60 * 24 * 1,
        },
        {},
      ],
      ...this.chartOptions,
    };
  }

  private resizeChart(): void {
    this.chart.resize({
      width: this.$refs.stepChart.parentElement?.clientWidth,
      height: 450,
    });
  }

  private created(): void {
    window.addEventListener('resize', this.resizeChart);
  }

  private mounted(): void {
    this.chart = echarts.init(this.$refs.stepChart, undefined, {
      locale: 'de',
      width: this.$refs.stepChart.parentElement?.clientWidth,
      height: 450,
      renderer: 'svg',
    });
    this.chart.setOption<ChartOptions>(this.options, { notMerge: true, lazyUpdate: true });

    this.chart.on('datazoom', () => {
      const options = this.chart.getOption();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.zoomIntervalStartValue = options?.dataZoom[0].startValue;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.zoomIntervalEndValue = options?.dataZoom[0].endValue;
    });
  }

  private destroyed(): void {
    window.removeEventListener('resize', this.resizeChart);
  }

  private get timeDifferenceOfPointsInDays(): number {
    const setPoints = this.sets.flatMap(({ points }) => points);

    const timeOfSetPoints = setPoints.map(({ x }) => x as ISODate);

    const sortedTimeOfSetPoints = sortBy(timeOfSetPoints);

    if (sortedTimeOfSetPoints.length === 0) {
      return 0;
    }

    const firstPoint = DateTime.fromISO(sortedTimeOfSetPoints[0]);

    const lastPoint = DateTime.fromISO(sortedTimeOfSetPoints[sortedTimeOfSetPoints.length - 1]);

    return Math.abs(firstPoint.diff(lastPoint, ['days']).days);
  }

  private get xAxisTimeFormat(): string {
    switch (true) {
      case this.timeDifferenceOfPointsInDays < 2:
        return 'dd LLL HH:mm';
      case this.timeDifferenceOfPointsInDays < 14:
        return 'dd LLL';
      case this.timeDifferenceOfPointsInDays < 30:
        return "K'W' W";
      case this.timeDifferenceOfPointsInDays < 365:
        return 'dd.LL';
      case this.timeDifferenceOfPointsInDays >= 365:
        return 'yyyy LLL';
      default:
        return 'x';
    }
  }

  private zoomIntervalDifferenceInDays(startValue: string, endValue: string): number {
    const start = DateTime.fromJSDate(new Date(startValue));

    const end = DateTime.fromJSDate(new Date(endValue));

    return Math.abs(start.diff(end, ['days']).days);
  }

  private xAxisLabelFormatter(value: number): string {
    const time = DateTime.fromJSDate(new Date(value));

    if (time.day === 1) {
      return time.toFormat('LLL');
    }

    const zoomIntervalDifference = this.zoomIntervalDifferenceInDays(
      this.zoomIntervalStartValue,
      this.zoomIntervalEndValue,
    );

    switch (true) {
      case zoomIntervalDifference < 5:
        return time.toFormat('dd LLL HH:mm');
      case zoomIntervalDifference < 14:
        return time.toFormat('dd LLL');
      case zoomIntervalDifference < 30:
        return time.toFormat("K'W' W");
      case zoomIntervalDifference < 365:
        return time.toFormat('dd.LL');
      case zoomIntervalDifference >= 365:
        return time.toFormat('yyyy LLL');
      default:
        return time.toFormat(this.xAxisTimeFormat);
    }
  }

  @Watch('sets')
  private onChartDataChange(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.options.dataZoom[0].startValue = this.zoomIntervalStartValue;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.options.dataZoom[0].endValue = this.zoomIntervalEndValue;
    this.chart.setOption<ChartOptions>(this.options, { notMerge: true, lazyUpdate: true });
  }
}
