




import { BooleanProp } from '@/util/prop-decorators';
import { Component, Model, Vue } from 'vue-property-decorator';
import { Input } from './model';

@Component({})
export default class InputTextarea extends Vue implements Input {
  @Model('update')
  private readonly value!: unknown;

  @BooleanProp()
  private readonly error!: boolean;

  @BooleanProp()
  private readonly noTrim!: boolean;

  public pristine = true;

  private get model(): string {
    return this.value === undefined || this.value === null ? '' : String(this.value);
  }

  private set model(value: string) {
    this.$emit('update', value);
  }

  public get normalizedValue(): string {
    return this.noTrim ? this.model : this.model.trim();
  }

  public get empty(): boolean {
    return this.normalizedValue === '';
  }
}
