





import { Component, Vue } from 'vue-property-decorator';
import { BooleanProp } from '@/util/prop-decorators';

@Component
export default class StepLabel extends Vue {
  @BooleanProp()
  private error!: boolean;
}
