import { render, staticRenderFns } from "./RectangleIcon.vue?vue&type=template&id=21c17e48&scoped=true&lang=pug&"
import script from "./RectangleIcon.vue?vue&type=script&lang=ts&"
export * from "./RectangleIcon.vue?vue&type=script&lang=ts&"
import style0 from "./RectangleIcon.vue?vue&type=style&index=0&id=21c17e48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21c17e48",
  null
  
)

export default component.exports