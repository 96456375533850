




















import { EnumProp, NumberProp, StringProp, BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

interface Digit {
  digit: string;
  index: number;
  colored: boolean;
  separator: boolean;
}

@Component
export default class Counter extends Vue {
  @NumberProp()
  private readonly value?: number;

  @NumberProp(7)
  private readonly digits!: number;

  @NumberProp()
  private readonly fractionDigits?: number;

  @EnumProp('DARK', 'RED', 'BLUE', 'ORANGE', 'PURPLE')
  private readonly digitColor!: 'DARK' | 'RED' | 'BLUE' | 'ORANGE' | 'PURPLE';

  @StringProp()
  private readonly icon!: string;

  @BooleanProp()
  private readonly slidable!: boolean;

  @BooleanProp()
  private readonly prevDisabled!: boolean;

  @BooleanProp()
  private readonly nextDisabled!: boolean;

  private get normalizedFractionDigits(): number {
    if (this.fractionDigits !== undefined) {
      return this.fractionDigits;
    }

    if (this.value === undefined) {
      return 0;
    }

    const magnitude = Math.floor(1 + Math.log10(this.value));

    return Math.min(3, Math.max(this.digits - magnitude, 0));
  }

  private get displayDigits(): Digit[] {
    if (this.value === undefined || isNaN(this.value)) {
      return this.prepareDisplayDigits('', 'X', 0, true);
    }

    const normalizedValue = (this.value ?? 0) * Math.pow(10, this.normalizedFractionDigits);

    return this.prepareDisplayDigits(
      normalizedValue.toFixed(0).slice(-this.digits),
      '0',
      this.digits - this.normalizedFractionDigits,
      false,
    );
  }

  private prepareDisplayDigits(digits: string, padChar: string, colorOffset: number, empty: boolean): Digit[] {
    return digits
      .padStart(this.digits, padChar)
      .split('')
      .map((digit, index) => ({
        digit,
        index,
        colored: index < colorOffset,
        separator: !empty && index === colorOffset,
      }));
  }
}
