import { render, staticRenderFns } from "./InputCheckboxList.global.vue?vue&type=template&id=04a52e4d&scoped=true&lang=pug&"
import script from "./InputCheckboxList.global.vue?vue&type=script&lang=ts&"
export * from "./InputCheckboxList.global.vue?vue&type=script&lang=ts&"
import style0 from "./InputCheckboxList.global.vue?vue&type=style&index=0&id=04a52e4d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a52e4d",
  null
  
)

export default component.exports