




















import { StringProp, BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import UiClickable from './Clickable.global.vue';

@Component({ components: { UiClickable }, inheritAttrs: false })
export default class ButtonWithIcon extends Vue {
  @StringProp()
  private readonly icon?: string;

  @BooleanProp()
  private readonly after!: boolean;

  @BooleanProp()
  private readonly front!: boolean;

  @BooleanProp()
  private readonly back!: boolean;

  @BooleanProp()
  private readonly fixedWidth!: boolean;

  @BooleanProp()
  private readonly inline!: boolean;

  @BooleanProp()
  private readonly busy!: boolean;

  // Computed property to determine button classes
  get buttonClasses(): any {
    return {
      'button-disabled': this.busy || this.$attrs.disabled,
      // Add other dynamic classes here
    };
  }

  public slotName(slotName: string): string {
    const defaultSlotName = this.front ? 'front' : this.back ? 'back' : this.after ? 'after' : 'before';
    if (slotName === defaultSlotName) {
      return 'default';
    }
    return slotName;
  }
}
