



















import { EnumProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { Status } from './model';

@Component({
  data() {
    return {
      title: undefined,
    };
  },
})
export default class StatusCard extends Vue {
  @EnumProp(...Object.values(Status))
  private readonly status!: Status;
}
