






import { Component, Vue } from 'vue-property-decorator';
import { NumberProp, StringProp } from '@/util/prop-decorators';

@Component({})
export default class Numerus extends Vue {
  @NumberProp(0)
  private readonly count!: number;

  @StringProp('')
  private readonly singular!: string;

  @StringProp('')
  private readonly plural!: string;

  private get isSingular(): boolean {
    return this.count === 1;
  }

  private get correctNumerus(): string {
    return this.isSingular ? this.singular : this.plural;
  }

  private get isPlural(): boolean {
    return this.count !== 1;
  }
}
