











import { BooleanProp, StringProp, RequiredProp, OptionalProp } from '@/util/prop-decorators';
import moment, { DurationInputArg1, Moment, MomentInput } from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import schedule from './moment-ticker';

type NonEmptyMomentInput = Exclude<MomentInput, void>;
type NonEmptyDurationInput = Exclude<DurationInputArg1, void>;

@Component({
  data() {
    return { now: undefined };
  },
})
export default class RelativeTime extends Vue {
  @RequiredProp()
  public date!: NonEmptyMomentInput;
  @OptionalProp()
  public min?: NonEmptyDurationInput;
  @OptionalProp()
  public max?: NonEmptyDurationInput;
  @OptionalProp()
  public reference?: NonEmptyMomentInput;
  @BooleanProp()
  public to!: boolean;
  @BooleanProp()
  public clamp!: boolean;
  @BooleanProp(true)
  public suffix!: boolean;
  @StringProp('L')
  public format!: string;
  @StringProp('Ungültiges Datum {{ date }}')
  public invalidMessage!: string;
  @StringProp('')
  public emptyMessage!: string;

  public now!: Moment;

  public get referenceDate(): Moment {
    return this.reference === undefined ? this.now : moment(this.reference);
  }

  public get momentDate(): Moment {
    return moment(this.date);
  }

  public get relativeDate(): string {
    const date = this.momentDate;
    const referenceDate = this.clamp ? moment[this.to ? 'min' : 'max'](date, this.referenceDate) : this.referenceDate;

    return date[this.to ? 'to' : 'from'](referenceDate, !this.suffix);
  }

  public get formattedDate(): string {
    return this.momentDate.format(this.format);
  }

  public get inRange(): boolean {
    if (this.min !== undefined && this.referenceDate.clone().add(moment.duration(this.min)).isAfter(this.momentDate)) {
      return false;
    }

    if (this.max !== undefined && this.referenceDate.clone().add(moment.duration(this.max)).isBefore(this.momentDate)) {
      return false;
    }

    return true;
  }

  public get displayDate(): string {
    return this.inRange ? this.relativeDate : this.formattedDate;
  }

  public created(): void {
    let unschedule: undefined | (() => void);

    this.$watch(
      () => this.reference === undefined,
      (live) => {
        if (!live) {
          if (unschedule) {
            this.$off('hook:beforeDestroy', unschedule);
            unschedule();
            unschedule = undefined;
          }

          return;
        }

        if (!this.now) {
          this.now = moment();
        }

        if (!unschedule) {
          unschedule = schedule((now) => (this.now = now));
          this.$once('hook:beforeDestroy', unschedule);
        }
      },
      { immediate: true },
    );
  }
}
