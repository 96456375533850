



















import { BooleanProp, ArrayProp } from '@/util/prop-decorators';
import { Component, Vue, Model } from 'vue-property-decorator';
import { Input, Option } from './model';

@Component
export default class InputRadio<T = unknown> extends Vue implements Input {
  @Model('update')
  private readonly value!: T;

  @BooleanProp()
  private readonly indeterminate!: boolean;

  @BooleanProp()
  private readonly hidden!: boolean;

  @BooleanProp()
  private readonly implicit!: boolean;

  @BooleanProp()
  private readonly disabled!: boolean;

  @ArrayProp(() => [])
  private options!: Option[];

  public pristine = true;

  private get model(): T {
    return this.value;
  }

  private set model(value: T) {
    this.$emit('update', value);
  }

  public get normalizedValue(): T {
    return this.model;
  }

  public get empty(): boolean {
    return false;
  }
}
