








import { toLength } from '@/util/css-length';
import { LengthProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import { StatePropertyMixin } from './state-property-mixin';

@Component
export default class IconStateIndicator extends Mixins(StatePropertyMixin) {
  @LengthProp()
  private readonly size?: string | number;

  private get style(): Record<string, string | undefined> {
    const size = toLength(this.size);

    return { width: size, height: size };
  }
}
