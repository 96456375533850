








import { Component, Vue } from 'vue-property-decorator';
import { BooleanProp } from '@/util/prop-decorators';

@Component
export default class CheckBox extends Vue {
  @BooleanProp()
  private value!: boolean;
}
