



















import ArrowLeft from './arrow-left.svg?vue';
import { StringProp, BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { ArrowLeft } })
export default class ContentHeader extends Vue {
  @StringProp()
  public readonly headline?: string;
  @StringProp()
  public readonly suffix?: string;
  @BooleanProp()
  public readonly backLink!: boolean;
}
