













import { NumberProp, EnumProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { scaleLinear, ScaleLinear } from 'd3-scale';

@Component
export default class ProgressBarWithRuler extends Vue {
  @StringProp()
  private readonly label!: string;
  @NumberProp()
  private readonly value?: number;
  @EnumProp('GREEN', 'RED', 'ORANGE')
  private readonly barColor!: string;
  @EnumProp('GRAY', 'GREEN', 'RED', 'ORANGE')
  private readonly trackColor!: string;
  @NumberProp(0)
  private readonly minValue!: number;
  @NumberProp(100)
  private readonly maxValue!: number;

  private get scale(): ScaleLinear<number, number> {
    return scaleLinear().domain([this.minValue, this.maxValue]).clamp(true).range([0, 100]);
  }
}
