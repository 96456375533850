




























import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue, Watch } from 'vue-property-decorator';
import arrowLeft from './arrow-left.svg';
import arrowLeftDouble from './arrow-left-double.svg';
import arrowRight from './arrow-right.svg';
import arrowRightDouble from './arrow-right-double.svg';
import LeftToRightMultiSelectItem from './LeftToRightMultiSelectItem.vue';

export interface Item {
  id: string;
  label: string;
  meta?: unknown;
  children?: Item[];
}

@Component({
  components: {
    LeftToRightMultiSelectItem,
  },
  data() {
    return {
      leftColumn: [],
      rightColumn: [],
      leftSelected: [],
      rightSelected: [],
    };
  },
})
export default class LeftToRightMultiSelect extends Vue {
  @ArrayProp()
  private items!: Item[];

  @ArrayProp()
  private selectedItemIds!: string[];

  private leftColumn!: Item[];
  private rightColumn!: Item[];

  private leftSelected!: Item[];

  private rightSelected!: Item[];

  private arrowLeft = arrowLeft;
  private arrowLeftDouble = arrowLeftDouble;
  private arrowRight = arrowRight;
  private arrowRightDouble = arrowRightDouble;

  private bus = new Vue();

  @Watch('items')
  private setLeftColumn(): void {
    this.leftColumn = this.items || [];
  }

  @Watch('selectedItemIds')
  private setRightColumn(): void {
    this.rightColumn = this.flatLeftColumn.filter((item) => this.selectedItemIds.includes(item.id));
  }

  private updateSelected(item: Item, side: string): void {
    if (side === 'left') {
      this.leftSelected.push(item);
    } else {
      this.rightSelected.push(item);
    }
  }

  private get flatLeftColumn(): Item[] {
    const result: Item[] = [];

    function flat(data: Item[], parentLabel: string | undefined, level = 0): void {
      data.forEach((item) => {
        const label = parentLabel === undefined ? item.label : parentLabel + '/' + item.label;
        result.push({
          ...item,
          label: label,
          children: [],
        });
        if (item.children) {
          flat(item.children, label, level + 1);
        }
      });
    }
    flat(this.leftColumn, undefined);

    return result;
  }

  private removeSelected(item: Item, side: string): void {
    if (side === 'left') {
      this.leftSelected = this.leftSelected.filter((selected) => selected !== item);
    } else {
      this.rightSelected = this.rightSelected.filter((selected) => selected !== item);
    }
  }

  private moveToRight(): void {
    if (this.leftSelected.length > 0) {
      this.leftSelected.forEach((selected) => {
        const existingRightItem = this.findItem(this.rightColumn, selected.id);
        const flatLeftItem = this.findItem(this.flatLeftColumn, selected.id);
        if (!existingRightItem && flatLeftItem) {
          this.rightColumn.push(flatLeftItem);
        }
      });
      this.leftSelected = [];
    }
    this.$emit('update', this.rightColumn);
  }

  private moveToLeft(): void {
    if (this.rightSelected.length > 0) {
      this.rightSelected.forEach((selected) => {
        this.rightColumn = this.rightColumn.filter((item) => item.id !== selected.id);
      });
    }
    this.rightSelected = [];
    this.$emit('update', this.rightColumn);
  }

  private moveAllToRight(): void {
    this.rightColumn = this.leftColumn;
    this.rightSelected = [];
    this.leftSelected = [];
    this.$emit('update', this.rightColumn);
  }

  private moveAllToLeft(): void {
    this.rightColumn = [];
    this.$emit('update', this.rightColumn);
  }

  private findItem(data: Item[], id: string): Item | undefined {
    for (const item of data) {
      if (item.id === id) {
        return item;
      }
      if (item.children) {
        const found = this.findItem(item.children, id);
        if (found) {
          return found;
        }
      }
    }

    return undefined;
  }

  public created(): void {
    this.bus.$on('updateSelected', (item: Item, side: string) => this.updateSelected(item, side));
    this.bus.$on('removeSelected', (item: Item, side: string) => this.removeSelected(item, side));
  }
}
