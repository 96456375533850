




import { toLength } from '@/util/css-length';
import { LengthProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Icon extends Vue {
  @LengthProp()
  private readonly size?: string | number;

  @StringProp()
  private readonly icon?: string;

  private get style(): Record<string, string | undefined> {
    const size = toLength(this.size);

    return { width: size, height: size };
  }

  private get iconSrc(): NodeRequire {
    return require(`./icons/${this.icon}.svg`);
  }
}
