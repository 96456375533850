
import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { ChartSet } from './model';

@Component
export default class ChartSetsMixin extends Vue {
  @ArrayProp(true)
  protected sets!: ChartSet[];
}
