





import { Component, Vue } from 'vue-property-decorator';
import { EnumProp, StringProp } from '@/util/prop-decorators';

@Component({ inheritAttrs: false })
export default class Emblem extends Vue {
  @StringProp()
  private src?: string;

  @StringProp(true)
  private label!: string;

  @EnumProp(false, 'blue', 'red')
  private borderColor?: string;
}
