










import { Vue, Component } from 'vue-property-decorator';
import { ObjectProp } from '@/util/prop-decorators';
import { Component as ComponentType } from 'vue';
import KoneLiftVendorApiForm from './form/KoneLiftVendorApiForm.vue';
import { BindingConfig } from '../model';

@Component
export default class BindingVendorApiConfig extends Vue {
  @ObjectProp()
  private readonly config!: BindingConfig;

  private created(): void {
    if (!this.component) {
      return;
    }
    if (!this.config.vendorApi) {
      this.config.vendorApi = {};
    }
  }

  private get component(): ComponentType | undefined {
    return this.config.role === 'KONE_LIFT' ? KoneLiftVendorApiForm : undefined;
  }

  private get apiName(): string | undefined {
    switch (this.config.role) {
      case 'KONE_LIFT':
        return 'Kone';
      default:
        return undefined;
    }
  }
}
