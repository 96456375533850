









import UiButton from '@/components/clickables/Button.global.vue';
import UiModal from '@/features/ui/modal/Modal.global.vue';
import { Component, Vue } from 'vue-property-decorator';
import BindingWizard from './BindingWizard.vue';
import plus from './icons/plus.svg';
import { FunctionProp } from '@/util/prop-decorators';
import { AddAction } from './model';

@Component({
  components: { BindingWizard, UiButton },
  inheritAttrs: false,
})
export default class BindingWizardModal extends Vue {
  private open = false;
  private plus = plus;

  public readonly $refs!: { modal: UiModal };

  @FunctionProp()
  private addAction!: AddAction;

  private get decoratedAddAction(): AddAction {
    return async (...args): Promise<void> => {
      const result = await this.addAction(...args);

      this.open = false;

      await this.$refs.modal.hidden();

      return result;
    };
  }
}
