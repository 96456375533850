





















import { Component, Model, Vue } from 'vue-property-decorator';
import { StringProp, BooleanProp, ArrayProp } from '@/util/prop-decorators';
import Multiselect from 'vue-multiselect';
import '../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css';
import { SelectOption } from './model';

@Component({
  inheritAttrs: false,
  components: { Multiselect },
})
export default class VueMultiselect extends Vue {
  @Model('update')
  private readonly value!: SelectOption[];
  @ArrayProp(true)
  private readonly options!: SelectOption[];
  @BooleanProp()
  private readonly multiple!: boolean;
  @BooleanProp()
  private readonly taggable!: boolean;
  @BooleanProp()
  private readonly error!: boolean;
  @StringProp()
  private readonly errorMessage!: string;
  @StringProp()
  private readonly label!: string;
  @StringProp()
  private readonly placeholder?: string;
  @StringProp()
  private readonly tooltip?: string;
  @StringProp('?')
  private readonly tooltipLabel!: string;

  selectedItems: SelectOption[] = [];
  onChange(items: SelectOption[]): void {
    this.selectedItems = items;
    this.$emit('update', this.selectedItems);
  }
}
