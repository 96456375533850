
import { toLength } from '@/util/css-length';
import { LengthProp } from '@/util/prop-decorators';
import { CreateElement, VNode } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class GridList extends Vue {
  @LengthProp('auto')
  private readonly minColumnWidth!: string | number;

  @LengthProp('auto')
  private readonly rowHeight!: string | number;

  @LengthProp()
  private readonly gap?: string | number;

  private get gridStyles(): Record<string, string | undefined> {
    return {
      'grid-template-columns': `repeat(auto-fill, minmax(${toLength(this.minColumnWidth)}, 1fr))`,
      'grid-auto-rows': toLength(this.rowHeight),
      gap: toLength(this.gap),
    };
  }

  public render(h: CreateElement): VNode {
    const def = this.$scopedSlots.default;
    const defaultSlot = def ? def({}) : undefined;

    const wrappedDefaultSlot = (defaultSlot || [])
      .map((node) => ({ node, key: node.key as string | number }))
      .filter(({ key }) => typeof key === 'string' || typeof key === 'number')
      .map(({ node, key }) => {
        const scale = h('div', { staticClass: 'scale' }, [node]);
        return h('div', { staticClass: 'cell', key }, [scale]);
      });

    return h(
      'transition-group',
      {
        ref: 'parent',
        tag: 'div',
        staticClass: 'grid-list',
        props: { name: 'grid-list' },
        style: this.gridStyles,
        on: { beforeLeave: this.beforeLeave },
      },
      wrappedDefaultSlot,
    );
  }

  public beforeLeave(el: HTMLElement): void {
    const { width, height } = window.getComputedStyle(el);
    el.style.left = `${el.offsetLeft}px`;
    el.style.top = `${el.offsetTop}px`;
    el.style.width = width;
    el.style.height = height;
  }
}
