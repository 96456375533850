export const INPUT_TREE_SELECT = Symbol('INPUT_TREE_SELECT');

export interface TreeModel<T = unknown> {
  readonly rootNodes: T[];
  isNodeSelectable(node: T): boolean;
  idOf(node: T): string;
  childrenOf(node: T): T[];
  parentOf(node: T): T | undefined;
  labelOf(node: T): string;
  compare(a: T, b: T): number;
  loadNodePath(node: T): void | Promise<void>;
}
