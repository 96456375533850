





















import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import UiClickable from '@/components/clickables/Clickable.global.vue';
import CalendarIcon from './calendar.svg?vue';
import { MonthPicker as VMonthPicker } from 'vue-month-picker';
import { BooleanProp, DateProp, OptionalProp, StringProp } from '@/util/prop-decorators';

type MonthPickerData = {
  from: Date;
  to: Date;
  month: string;
  monthIndex: number;
  year: number;
};

@Component({
  components: {
    VMonthPicker,
    UiClickable,
    CalendarIcon,
  },
})
export default class MonthPicker extends Vue {
  @OptionalProp()
  private readonly value?: Date | string | number | null;

  @StringProp()
  private readonly label?: string;

  @DateProp()
  private readonly minDate?: Date;

  @DateProp()
  private readonly maxDate?: Date;

  @BooleanProp(false)
  private readonly showCurrentYearOnly!: boolean;

  @StringProp('auto')
  private readonly width?: string;

  @StringProp('MMMM YYYY')
  private readonly format?: string;

  private monthPickerOpen = false;

  private defaultMonth = 1;

  private defaultYear = new Date().getFullYear();

  private disableBtn = false;

  private get formattedDate(): string | undefined {
    return this.value === undefined || this.value === null ? undefined : moment(this.value).utc().format(this.format);
  }

  private setValue(evt: MonthPickerData): void {
    this.$emit('input', new Date(evt.year, evt.monthIndex, 0));
  }

  private mounted(): void {
    if (this.value) {
      const currentDate = moment(this.value);
      this.defaultMonth = currentDate.month();
      this.defaultYear = currentDate.year();
    }

    if (this.showCurrentYearOnly) {
      this.disableBtn = true;
    }
  }

  private get computedMinDate(): Date | null {
    if (this.showCurrentYearOnly && !this.minDate) {
      return new Date(new Date().getFullYear() - 1, 11, 30);
    }
    if (this.minDate) {
      return this.minDate;
    }

    return null;
  }

  private get computedMaxDate(): Date | null {
    if (this.showCurrentYearOnly && !this.maxDate) {
      return new Date(new Date().getFullYear(), 11, 30);
    }
    if (this.maxDate) {
      return this.maxDate;
    }

    return null;
  }
}
