

































import { Option } from '@/features/ui/inputs/model';
import { ArrayProp, BooleanProp, OptionalProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { csv } from './csv';
import {
  ExportColumn,
  Exporter,
  ExportRowsLike,
  NormalizedExportColumn,
  normalizeExportColumn,
  toExportRows,
} from './model';

interface FormatOption {
  label: string;
  extension?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- can not use unknown because of generic bivariance
  export: Exporter<any>;
}

interface FormData {
  filename: string;
  format: FormatOption;
  columnGroups: Record<string, string[]>;
}

@Component
export default class DataExport<T> extends Vue {
  @OptionalProp(() => [])
  private readonly rows!: ExportRowsLike<T>;

  @ArrayProp(() => [])
  private readonly columns!: ExportColumn<T>[];

  @StringProp('export')
  private readonly filename!: string;

  @BooleanProp()
  private readonly includeColumnNames!: boolean;

  @BooleanProp()
  private readonly disabled!: boolean;

  @ArrayProp()
  private readonly selectedColumns?: string[];

  @ArrayProp()
  private readonly enabledColumns?: string[];

  @ArrayProp(() => [])
  private readonly disabledColumns!: string[];

  @ArrayProp(() => [])
  private readonly groups!: string[];

  @StringProp('Exportieren')
  private readonly title!: string;

  private readonly formatOptions: FormatOption[] = [{ label: 'CSV', extension: '.csv', export: csv }];

  private get normalizedColumns(): NormalizedExportColumn<T>[] {
    return this.columns.map(normalizeExportColumn);
  }

  private get filteredColumns(): NormalizedExportColumn<T>[] {
    const enabledColumnSet = new Set(this.enabledColumns ?? this.columns.map(({ name }) => name));
    const disabledColumnSet = new Set(this.disabledColumns);

    return this.normalizedColumns
      .filter(({ exportable }) => exportable)
      .filter(({ name }) => enabledColumnSet.has(name))
      .filter(({ name }) => !disabledColumnSet.has(name));
  }

  private get selectableColumns(): NormalizedExportColumn<T>[] {
    return this.filteredColumns.filter(({ selectable }) => selectable);
  }

  private get columnOptionGroups(): { key: string; path: string; label: string; options: Option<string>[] }[] {
    return ['', ...this.groups]
      .map((currentGroup) => ({
        key: btoa(currentGroup),
        path: `columnGroups.${btoa(currentGroup)}`,
        label: currentGroup === '' ? 'Felder' : currentGroup,
        options: this.selectableColumns
          .filter(({ group }) => group === currentGroup)
          .map(({ name, label }) => ({ value: name, label })),
      }))
      .filter(({ options }) => options.length > 0);
  }

  private get initialData(): FormData {
    const selectedColumnSet = new Set(this.selectedColumns ?? this.selectableColumns.map(({ name }) => name));

    return {
      filename: this.filename,
      format: this.formatOptions[0],
      columnGroups: Object.fromEntries(
        this.columnOptionGroups.map(({ key, options }) => [
          key,
          options.filter(({ value }) => selectedColumnSet.has(value)).map(({ value }) => value),
        ]),
      ),
    };
  }

  private async exportAction({ filename, format, columnGroups }: FormData): Promise<void> {
    const columns = Object.values(columnGroups).flat();
    const selectedColumns = this.filteredColumns.filter(
      ({ name, selectable }) => !selectable || columns.includes(name),
    );

    await format.export({
      rows: toExportRows(this.rows, selectedColumns),
      columns: selectedColumns,
      filename: filename + (format.extension ?? ''),
      includeColumnNames: this.includeColumnNames,
    });
  }
}
