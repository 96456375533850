







import { Component, Vue } from 'vue-property-decorator';
import { StringProp } from '@/util/prop-decorators';

@Component
export default class FloorPlanPanel extends Vue {
  @StringProp(true)
  public name!: string;
}
