





import { BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Label extends Vue {
  @BooleanProp()
  private readonly red!: boolean;

  @BooleanProp()
  private readonly orange!: boolean;

  @BooleanProp()
  private readonly green!: boolean;

  @BooleanProp()
  private readonly grey!: boolean;
}
