



















import { EnumProp, BooleanProp, NumberProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import IotGatewayIcon from './iot-gateway.svg?vue';

@Component({ components: { IotGatewayIcon } })
export default class IotGatewayContent extends Vue {
  @EnumProp('GRAY', 'GREEN', 'RED')
  private readonly iconColor!: 'GRAY' | 'GREEN' | 'RED';
  @NumberProp(true)
  private readonly onlineCount!: number;
  @NumberProp(true)
  private readonly totalCount!: number;
  @BooleanProp()
  private readonly compact!: boolean;
}
