





import { ConnectionStatus, ConnectionStatusProp } from '@/components/sensor/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import Connection from './images/connection.svg?vue';

@Component({ components: { Connection } })
export default class SensorConnectionStatus extends Vue {
  @ConnectionStatusProp(true)
  public status!: ConnectionStatus;
}
