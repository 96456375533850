






import Cell from '@/features/ui/layout/Cell.global.vue';
import Flex from '@/features/ui/layout/Flex.global.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { Flex, Cell }, inheritAttrs: false })
export default class Container extends Vue {}
