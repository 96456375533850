




















import { NumberProp, EnumProp, FunctionProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { scaleLinear, ScaleLinear } from 'd3-scale';
import { range, uniqBy } from 'lodash';

@Component
export default class ProgressBarWithRuler extends Vue {
  @StringProp()
  private readonly label!: string;
  @NumberProp()
  private readonly value?: number;
  @EnumProp('GREEN', 'RED', 'ORANGE')
  private readonly barColor!: string;
  @EnumProp('GRAY', 'GREEN', 'RED', 'ORANGE')
  private readonly trackColor!: string;
  @FunctionProp((value) => value.toLocaleString(undefined, { maximumFractionDigits: 0 }))
  private readonly format!: (value: number) => string;
  @NumberProp(0)
  private readonly minValue!: number;
  @NumberProp(100)
  private readonly maxValue!: number;
  @NumberProp()
  private readonly minRulerValue?: number;
  @NumberProp()
  private readonly maxRulerValue?: number;
  @NumberProp(10)
  private readonly primaryRulerInterval!: number;
  @NumberProp(1)
  private readonly secondaryRulerInterval!: number;
  @NumberProp(10)
  private readonly labelRulerInterval!: number;

  private get scale(): ScaleLinear<number, number> {
    return scaleLinear().domain([this.minValue, this.maxValue]).clamp(true).range([0, 100]);
  }

  private get rulerSegments(): { value: number; primary: boolean }[] {
    const minRulerValue = Math.max(this.minRulerValue ?? this.minValue, this.minValue);
    const maxRulerValue = Math.min(this.maxRulerValue ?? this.maxValue, this.maxValue);

    const primaryRuler = range(
      minRulerValue,
      maxRulerValue +
        this.primaryRulerInterval * Number((maxRulerValue - minRulerValue) % this.primaryRulerInterval === 0),
      this.primaryRulerInterval,
    ).map((value) => ({ value, primary: true }));
    const secondaryRuler = range(
      minRulerValue,
      maxRulerValue +
        this.secondaryRulerInterval * Number((maxRulerValue - minRulerValue) % this.secondaryRulerInterval === 0),
      this.secondaryRulerInterval,
    ).map((value) => ({ value, primary: false }));

    return uniqBy([...primaryRuler, ...secondaryRuler], 'value').sort((a, b) => a.value - b.value);
  }

  private get rulerLabels(): number[] {
    const minRulerValue = Math.max(this.minRulerValue ?? this.minValue, this.minValue);
    const maxRulerValue = Math.min(this.maxRulerValue ?? this.maxValue, this.maxValue);

    return range(
      minRulerValue,
      maxRulerValue + this.labelRulerInterval * Number((maxRulerValue - minRulerValue) % this.labelRulerInterval === 0),
      this.labelRulerInterval,
    );
  }
}
