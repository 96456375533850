










import { Component, Vue } from 'vue-property-decorator';
import { ArrayProp, FunctionProp, ObjectProp } from '@/util/prop-decorators';
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import Step4 from './Step4.vue';
import { AddAction, BindingConfig, Option } from './model';

const DEFAULT_CONFIG = {
  name: '',
  role: '',
  manufacturer: '',
  serial: '',
} as const;

@Component({
  components: { Step1, Step2, Step3, Step4 },
  data() {
    return { config: undefined };
  },
})
export default class BindingWizard extends Vue {
  @ObjectProp(() => ({}))
  private readonly initialConfig!: Partial<BindingConfig>;

  @ArrayProp(true)
  public readonly roleOptions!: Option[];

  @ArrayProp(true)
  public readonly manufacturerOptions!: Option[];

  @FunctionProp()
  private addAction!: AddAction;

  public config!: BindingConfig;
  public step = 1;

  private created(): void {
    this.config = this.createConfig();
  }

  public async complete(): Promise<void> {
    const result = await this.addAction(this.config);

    this.config = this.createConfig();
    this.step = 1;

    return result;
  }

  public close(): void {
    this.$emit('close');
  }

  private createConfig(): BindingConfig {
    return Object.assign({}, DEFAULT_CONFIG, this.initialConfig);
  }
}
