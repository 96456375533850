
























import { Component, Model, Vue } from 'vue-property-decorator';

import lineChartIcon from '@/assets/images/chart/line.svg';
import barChartIcon from '@/assets/images/chart/bar.svg';
import { ChartStyle } from './model';

@Component
export default class ChartStyleSwitcher extends Vue {
  @Model('update', { type: String, required: true })
  private value!: ChartStyle;

  private readonly lineChartIcon = lineChartIcon;
  private readonly barChartIcon = barChartIcon;
  private readonly ChartStyle = ChartStyle;
}
