







import { Component, Vue } from 'vue-property-decorator';
import UiMap from './Map.global.vue';

@Component({ components: { UiMap }, inheritAttrs: false })
export default class MapPanel extends Vue {}
