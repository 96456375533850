











import { StringProp, OptionalProp, BooleanProp } from '@/util/prop-decorators';
import moment, { Moment, MomentInput } from 'moment';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Date extends Vue {
  @OptionalProp()
  public date?: Exclude<MomentInput, void>;

  @StringProp('L')
  public format!: string;

  @StringProp('Ungültiges Datum {{ date }}')
  public invalidMessage!: string;

  @StringProp('')
  public emptyMessage!: string;

  @BooleanProp()
  public wrap!: boolean;

  public get momentDate(): Moment {
    return moment(this.date);
  }

  public get formattedDate(): string {
    return this.momentDate.format(this.format);
  }
}
