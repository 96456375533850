import { render, staticRenderFns } from "./PageSize.global.vue?vue&type=template&id=dd29a4ca&scoped=true&lang=pug&"
import script from "./PageSize.global.vue?vue&type=script&lang=ts&"
export * from "./PageSize.global.vue?vue&type=script&lang=ts&"
import style0 from "./PageSize.global.vue?vue&type=style&index=0&id=dd29a4ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd29a4ca",
  null
  
)

export default component.exports