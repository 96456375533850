







import flatpickr from 'flatpickr';
import 'flatpickr/dist/themes/light.css';
import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import CalendarIcon from './icons/calendar.svg?vue';
import ClearIcon from './icons/clear.svg?vue';
import { MomentInput } from 'moment';
import { Instance } from 'flatpickr/dist/types/instance';

interface FlatpickrInput extends HTMLInputElement {
  _flatpickr: Instance;
}

@Component({
  components: {
    CalendarIcon,
    ClearIcon,
  },
})
export default class InputDateFlatPickr extends Vue {
  @Model('update')
  private readonly value?: MomentInput;

  @Prop({
    default: DateTime.now().startOf('year').toISO(),
  })
  private readonly minDate?: string;

  @Prop({
    default: DateTime.now().endOf('year').toISO(),
  })
  private readonly maxDate?: string;

  @Prop({
    default: 'd.m.Y',
  })
  private readonly dateFormat?: string;

  private get model(): MomentInput | string {
    if (this.value instanceof Date) {
      return DateTime.fromJSDate(this.value).toFormat('dd.LL.yyyy');
    }
    return this.value;
  }

  private set model(value: MomentInput | string) {
    if (typeof value === 'string') {
      this.$emit('update', DateTime.fromFormat(value, 'dd.MM.yyyy').toJSDate());
    } else {
      this.$emit('update', value);
    }
  }

  private clear(): void {
    this.model = undefined;
  }

  private toggleOpen(event: Event): void {
    const eventTarget = event.target as HTMLElement;
    if (eventTarget && eventTarget.parentElement) {
      const input = eventTarget.parentElement.querySelector('input') as FlatpickrInput;
      if (input) {
        (input._flatpickr as Instance).toggle();
      }
    }
  }

  private mounted(): void {
    flatpickr('.flatpickr-date-input input', {
      minDate: this.minDate,
      maxDate: this.maxDate,
      dateFormat: this.dateFormat,
    });
  }
}
