
















import UiClickable from '@/components/clickables/Clickable.global.vue';
import { BooleanProp, OptionalProp } from '@/util/prop-decorators';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import VDatepicker from 'vuejs-datepicker';
import CalendarIcon from './calendar.svg?vue';
import ClearIcon from './clear.svg?vue';

@Component({ components: { CalendarIcon, VDatepicker, UiClickable, ClearIcon }, inheritAttrs: false })
export default class Datepicker extends Vue {
  @OptionalProp()
  private readonly value?: Date | string | number | null;

  @BooleanProp()
  private readonly clearable!: boolean;

  @OptionalProp()
  private readonly emptyValue: unknown;

  private datepickerOpen = false;

  private get formattedDate(): string | undefined {
    return this.value === undefined || this.value === null ? undefined : moment(this.value).format('L');
  }
}
