





















import { differenceBy, isArray } from 'lodash';
import { Component, Vue, Model, Watch } from 'vue-property-decorator';
import { OptionalProp, ObjectProp, BooleanProp } from '@/util/prop-decorators';
import { Input } from '../model';
import { INPUT_TREE_SELECT, TreeModel } from './model';
import NodeList from './NodeList.vue';

@Component({
  components: { NodeList },
  data() {
    return { opennesses: {} };
  },
  provide() {
    return { [INPUT_TREE_SELECT]: this };
  },
})
export default class InputTreeSelect<T = unknown> extends Vue implements Input {
  @Model('update')
  private readonly value?: T[] | null;

  @ObjectProp(true)
  public readonly tree!: TreeModel<T>;

  @BooleanProp()
  public readonly implicit!: boolean;

  @BooleanProp()
  public readonly disabled!: boolean;

  @BooleanProp()
  private readonly openSelected!: boolean;

  @BooleanProp()
  private readonly openRoots!: boolean;

  @OptionalProp()
  private readonly emptyMessage?: string;

  public pristine = true;

  private opennesses!: Record<string, boolean>;

  public get model(): T[] {
    return isArray(this.value) ? this.value : [];
  }

  public set model(value: T[]) {
    this.pristine = false;
    this.$emit('update', value);
  }

  public get sortedModel(): T[] {
    return [...this.model].sort((a, b) => this.tree.compare(a, b));
  }

  public get normalizedValue(): T[] {
    return this.model;
  }

  public get empty(): boolean {
    return this.normalizedValue.length === 0;
  }

  public resetTree(): void {
    this.opennesses = {};
    if (this.openRoots) {
      for (const node of this.tree.rootNodes) {
        this.toggleOpenness(node, true);
      }
    }
    if (this.openSelected) {
      for (const node of this.loadedSelection) {
        let parent = this.tree.parentOf(node);
        while (parent) {
          this.toggleOpenness(parent, true);
          parent = this.tree.parentOf(parent);
        }
      }
    }
  }

  public hasSelectedDescendants(node: T): boolean {
    // TODO
    node;

    return false;
  }

  public hasSelectedAncestors(node: T): boolean {
    // TODO
    node;

    return false;
  }

  public isNodeOpen(node: T): boolean {
    return this.opennesses[this.tree.idOf(node)] ?? false;
  }

  public toggleOpenness(node: T, open?: boolean): void {
    const id = this.tree.idOf(node);

    if (this.tree.childrenOf(node).length > 0) {
      const openness = open !== undefined ? !open : this.opennesses[id] ?? false;
      this.$set(this.opennesses, id, !openness);
    }

    if (this.opennesses[id]) {
      this.tree.loadNodePath(node);
    }
  }

  @Watch('tree.rootNodes', { immediate: true })
  private rootNodesChanged(newRootNodes: T[], oldRootNodes: T[] = []): void {
    if (!this.openRoots) return;
    // open new root nodes when they are loaded
    for (const newNode of differenceBy(newRootNodes, oldRootNodes, (node) => this.tree.idOf(node))) {
      this.toggleOpenness(newNode, true);
    }
  }

  private get loadedSelection(): T[] {
    return this.model.filter((node) => this.tree.parentOf(node) || this.tree.rootNodes.includes(node));
  }

  @Watch('loadedSelection', { immediate: true })
  private loadedSelectionChanged(newSelection: T[], oldSelection: T[] = []): void {
    if (!this.openSelected) return;
    // open new selected nodes when they are loaded
    for (const newNode of differenceBy(newSelection, oldSelection, (node) => this.tree.idOf(node))) {
      let parent = this.tree.parentOf(newNode);
      while (parent) {
        this.toggleOpenness(parent, true);
        parent = this.tree.parentOf(parent);
      }
    }
  }
}
