import { get, isArray, isString, startCase } from 'lodash';

export type Accessor<T = unknown, V = unknown> = (row: T) => V;
export type TextFormatter<T = unknown, V = unknown> = (row: T, column: NormalizedExportColumn<T>, value: V) => string;

export interface ExportColumn<T = unknown> {
  /** Name of the column slot */
  name: string;
  exportable?: boolean;
  selectable?: boolean;
  /**
   * Accessor function to get this columns value or a property path compatible
   * with lodashs `get`
   */
  accessor?: string | Accessor<T>;
  label?: string;
  descriptions?: string[];
  group?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- can not use unknown because of generic bivariance
  formatText?: TextFormatter<T, any>;
  align?: 'left' | 'center' | 'right';
}

export interface NormalizedExportColumn<T = unknown> extends Required<Omit<ExportColumn<T>, 'accessor'>> {
  accessor: Accessor<T>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- can not use unknown because of generic bivariance
const DEFAULT_TEXT_FORMATTER: TextFormatter<any, any> = (row, column, value) => String(value ?? '');

function createAccessor<T>(accessor: string | Accessor<T>): Accessor<T> {
  return isString(accessor) ? (row) => get(row, accessor) as unknown : accessor;
}

export function normalizeExportColumn<T>({
  name,
  exportable = true,
  selectable = true,
  accessor = name,
  label = startCase(name),
  descriptions = [],
  group = '',
  formatText = DEFAULT_TEXT_FORMATTER,
  align = 'left',
}: ExportColumn<T>): NormalizedExportColumn<T> {
  return {
    name,
    exportable,
    selectable,
    accessor: createAccessor(accessor),
    label,
    descriptions,
    group,
    formatText,
    align,
  };
}

export type ExportRowsLike<T = unknown> =
  | T[]
  | ((columns: NormalizedExportColumn<T>[]) => T[] | Promise<T[]> | Generator<T> | AsyncGenerator<T>);

export type ExportRows<T = unknown> = () => AsyncGenerator<T>;

export function toExportRows<T>(rows: ExportRowsLike<T>, columns: NormalizedExportColumn<T>[]): ExportRows<T> {
  return async function* () {
    const normalizedRows = typeof rows === 'function' ? await rows(columns) : rows;

    if (isArray(normalizedRows)) {
      yield* normalizedRows;

      return;
    }

    for await (const row of normalizedRows) {
      yield row;
    }
  };
}

export interface ExporterOptions<T = unknown> {
  rows: ExportRows<T>;
  columns: NormalizedExportColumn<T>[];
  filename: string;
  includeColumnNames?: boolean;
}

export type Exporter<T = unknown> = (options: ExporterOptions<T>) => Promise<void>;
