import { render, staticRenderFns } from "./RadioButton.vue?vue&type=template&id=0580cdfb&scoped=true&lang=pug&"
import script from "./RadioButton.vue?vue&type=script&lang=ts&"
export * from "./RadioButton.vue?vue&type=script&lang=ts&"
import style0 from "./RadioButton.vue?vue&type=style&index=0&id=0580cdfb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0580cdfb",
  null
  
)

export default component.exports