





















import { ArrayProp, OptionalProp, ObjectProp, StringProp } from '@/util/prop-decorators';
import { find } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { Item } from './LeftToRightMultiSelect.vue';

@Component({
  name: 'left-to-right-multi-select-item',
})
export default class LeftToRightMultiSelectItem extends Vue {
  @ArrayProp()
  private data!: Item[];

  @OptionalProp()
  private depth!: string;

  @StringProp()
  private side!: string;

  @ArrayProp()
  private selected!: Item[];

  @ObjectProp()
  private bus!: Vue;

  private isOpen = true;

  private get indent(): Record<string, string> {
    return { paddingLeft: `${Number(this.depth) * 10}px` };
  }

  private toggleSelected(item: Item): void {
    const selectedItem = find(this.selected, { id: item.id });
    if (!selectedItem) {
      this.bus.$emit('updateSelected', item, this.side);
    } else {
      this.bus.$emit('removeSelected', item, this.side);
    }
  }

  private isSelected(id: number): boolean {
    return !!find(this.selected, { id });
  }
}
