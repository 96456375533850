




import { EnumProp } from '@/util/prop-decorators';
import { Component as ComponentType } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import ChartJSLineChart from './chartjs/ChartJSLineChart.vue';
import EChartsLineChart from './echarts/EChartsLineChart.vue';
import { LineChartType } from './model';

@Component
export default class LineChart extends Vue {
  @EnumProp(...Object.values(LineChartType))
  private readonly type!: LineChartType;

  private get component(): ComponentType {
    const componentMap = { [LineChartType.ECHARTS]: EChartsLineChart, [LineChartType.CHARTJS]: ChartJSLineChart };

    return componentMap[this.type];
  }
}
