















import { IntegerProp, NumberProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RatingSmiley extends Vue {
  @NumberProp(0.5, 0, 1)
  private readonly score!: number;

  @IntegerProp(10)
  private readonly size!: number;

  @IntegerProp(3, 1)
  private readonly strokeWidth!: number;

  private get viewBox(): string {
    return `0 0 ${this.size} ${this.size}`;
  }

  private get color(): string {
    if (this.score > 0.7) {
      return 'green';
    } else if (this.score > 0.4) {
      return 'yellow';
    } else {
      return 'red';
    }
  }

  private get smilePath(): string {
    return `M ${this.size * 0.25} ${this.size * (0.65 - (this.score - 0.5) * 0.2)}, C ${this.size * 0.4} ${
      this.size * (0.65 + (this.score - 0.5) * 0.2)
    }, ${this.size * 0.6} ${this.size * (0.65 + (this.score - 0.5) * 0.2)}, ${this.size * 0.75} ${
      this.size * (0.65 - (this.score - 0.5) * 0.2)
    }`;
  }
}
