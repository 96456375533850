






















import { Component, Model, Mixins } from 'vue-property-decorator';
import Id from '@/features/ui/mixins/id';
import { StringProp, BooleanProp, ArrayProp, OptionalProp } from '@/util/prop-decorators';

interface Option {
  value: unknown;
  label?: string;
}

@Component({ inheritAttrs: false })
export default class DropdownSelect extends Mixins(Id) {
  @Model('update')
  private readonly value!: unknown;
  @ArrayProp(() => [])
  private readonly options!: Option[];
  @BooleanProp()
  private readonly emptyOption!: boolean;
  @OptionalProp()
  private readonly emptyValue: unknown;
  @BooleanProp()
  private readonly error!: boolean;
  @StringProp()
  private readonly errorMessage?: string;
  @StringProp()
  private readonly tooltip?: string;
  @StringProp('?')
  private readonly tooltipLabel!: string;
}
