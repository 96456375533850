



















import EmblemRadioButton from '@/components/form/EmblemRadioButton.vue';
import { Component, Vue } from 'vue-property-decorator';
import BindingWizard from './BindingWizard.vue';
import StepControls from './StepControls.vue';
import StepHint from './StepHint.vue';
import StepLabel from './StepLabel.vue';

@Component({
  components: { EmblemRadioButton, StepControls, StepHint, StepLabel },
  data() {
    return {
      hoverDeviceLabel: undefined,
    };
  },
})
export default class Step2 extends Vue {
  public readonly $parent!: BindingWizard;

  private displayErrors = false;
  private hoverDeviceLabel?: string;

  private get valid(): boolean {
    return this.roleValid;
  }

  private get roleValid(): boolean {
    return this.$parent.config.role.length > 0;
  }

  private get roleLabel(): string {
    for (const option of this.$parent.roleOptions) {
      if (option.value === this.$parent.config.role) {
        return option.label;
      }
    }

    return '';
  }

  private onNext(): void {
    if (!this.valid) {
      this.displayErrors = true;

      return;
    }

    this.$parent.step++;
  }

  private get roleTooltipText(): string {
    if (this.displayErrors && !this.roleValid) {
      return 'Bitte wählen Sie die Rolle des Geräts aus!';
    } else {
      return this.hoverDeviceLabel || this.roleLabel || '\xa0';
    }
  }
}
