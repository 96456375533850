























import { ArrayProp, BooleanProp, NumberProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Offset, Placement, Position } from './model';

interface PlacementData {
  dragKey: string;
  grabPosition: Position;
}

@Component({
  data() {
    return {
      scale: 1,
      offset: { left: 0, top: 0 },
      moveReference: undefined,
      canvasRef: undefined,
      dragKey: undefined,
    };
  },
})
export default class ImageCanvas<T> extends Vue {
  @StringProp()
  private readonly src?: string;

  @ArrayProp(() => [])
  private readonly placements!: Placement<T>[];

  @BooleanProp()
  private readonly draggable!: boolean;

  @NumberProp(1)
  private readonly minScale!: number;

  @NumberProp(2)
  private readonly maxScale!: number;

  private scale!: number;
  private offset!: Offset;
  private moveReference?: { offset: Offset; startEvent: PointerEvent };
  private canvasRef?: HTMLDivElement;
  private dragKey?: string;

  public readonly $refs!: { canvas: HTMLDivElement; image: HTMLImageElement };

  private get positionedPlacements(): Placement<T>[] {
    return (
      this.placements
        // .filter(isPositionedPlacement)
        .map(({ key, value, position }) => ({ key, value, position: position ?? { x: -2, y: -2 } }))
        .sort((a, b) => a.position.y - b.position.y || a.position.x - b.position.x)
    );
  }

  private get canvasStyle(): Record<string, string> {
    return {
      left: `${this.offset.left}px`,
      top: `${this.offset.top}px`,
      '--scale': this.scale.toString(),
    };
  }

  private mounted(): void {
    document.addEventListener('dragend', this.dragEnd);
    this.canvasRef = this.$refs.canvas;
  }

  private updated(): void {
    this.canvasRef = this.$refs.canvas;
  }

  private beforeDestroy(): void {
    document.removeEventListener('dragend', this.dragEnd);
    this.stopMove();
  }

  @Watch('src')
  private reset(): void {
    this.scale = 1;
    this.offset = { left: 0, top: 0 };
  }

  private applyOffsetLimits(): void {
    if (this.canvasRef === undefined) {
      return;
    }

    const { offsetWidth, offsetHeight } = this.canvasRef;
    const halfWidth = Math.floor((offsetWidth * this.scale) / 2);
    const halfHeight = Math.floor((offsetHeight * this.scale) / 2);
    const left = Math.max(-halfWidth, Math.min(this.offset.left, halfWidth));
    const top = Math.max(-halfHeight, Math.min(this.offset.top, halfHeight));
    this.offset = { left, top };
  }

  private startMove(startEvent: PointerEvent): void {
    startEvent.preventDefault();

    this.moveReference = { offset: this.offset, startEvent };

    window.addEventListener('pointermove', this.move);
    window.addEventListener('pointerup', this.stopMove);
    window.addEventListener('pointercancel', this.cancelMove);
  }

  private move(event: PointerEvent): void {
    if (this.moveReference === undefined) {
      return;
    }

    const { offset, startEvent } = this.moveReference;
    this.offset = {
      left: offset.left + event.clientX - startEvent.clientX,
      top: offset.top + event.clientY - startEvent.clientY,
    };
    this.applyOffsetLimits();
  }

  private stopMove(): void {
    this.moveReference = undefined;

    window.removeEventListener('pointermove', this.move);
    window.removeEventListener('pointerup', this.stopMove);
    window.removeEventListener('pointercancel', this.cancelMove);
  }

  private cancelMove(): void {
    if (this.moveReference === undefined) {
      return;
    }

    this.offset = this.moveReference.offset;
    this.stopMove();
  }

  private modifyScale(delta: number): void {
    const { scale } = this;
    this.scale = Math.min(Math.max(this.minScale, scale + delta), this.maxScale);

    const ratio = this.scale / scale;
    this.offset.left *= ratio;
    this.offset.top *= ratio;
  }

  public dragStart(event: DragEvent, dragKey: string, grabOffset = false): void {
    if (
      !this.draggable ||
      this.canvasRef === undefined ||
      !event.dataTransfer ||
      !this.placements.find(({ key }) => key === dragKey)
    ) {
      return;
    }

    this.dragKey = dragKey;

    const grabPosition =
      grabOffset && event.target instanceof HTMLElement
        ? { x: event.offsetX - event.target.offsetWidth / 2, y: event.offsetY - event.target.offsetHeight / 2 }
        : { x: 0, y: 0 };
    const data: PlacementData = { dragKey, grabPosition };

    event.dataTransfer.setData('placement', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';

    if (!(event.target instanceof Node) || this.$el.contains(event.target)) {
      return;
    }

    const element = this.canvasRef.querySelector(`[data-placement=${CSS.escape(dragKey)}]`);
    if (element instanceof HTMLElement) {
      event.dataTransfer.setDragImage(element, element.offsetWidth / 2, element.offsetHeight / 2);
    }
  }

  private dragOver(event: DragEvent): void {
    if (event.dataTransfer?.types.includes('placement')) {
      event.dataTransfer.dropEffect = 'move';
      event.preventDefault();
    }
  }

  private dragEnd(event: DragEvent): void {
    if (this.dragKey === undefined) {
      return;
    }

    if (event.dataTransfer?.dropEffect !== 'move') {
      const placement = this.placements.find(({ key }) => key === this.dragKey);
      this.$emit('placement-dropped', { ...placement, position: undefined });
    }

    this.dragKey = undefined;
  }

  private drop(event: DragEvent): void {
    if (
      !event.dataTransfer?.types.includes('placement') ||
      this.canvasRef === undefined ||
      !(event.target instanceof HTMLElement)
    ) {
      return;
    }

    event.preventDefault();

    const { dragKey, grabPosition }: PlacementData = JSON.parse(event.dataTransfer.getData('placement'));
    const placement = this.placements.find(({ key }) => key === dragKey);
    if (placement === undefined) {
      return;
    }

    let { target } = event;
    let x = event.offsetX;
    let y = event.offsetY;

    // we have 3 different drop target cases
    if (target === this.$refs.image) {
      // 1. .image
      // event.offset* are in origin coordinates so we need to scale them
      x *= this.scale;
      y *= this.scale;
      // parent is .canvas and offset is 0, so we dont need to anything else
    } else {
      if (target.contains(this.canvasRef)) {
        // 2. .canvas or an ancestor of it is target, subtract all offsets until
        // target. all canvas ancestors that could be target are positioned
        // relative so we know it will be an offsetParent
        for (
          target = this.canvasRef;
          target !== event.target;
          target = target.offsetParent instanceof HTMLElement ? target.offsetParent : event.target
        ) {
          x -= target.offsetLeft;
          y -= target.offsetTop;
        }
      } else {
        // 3. some descendant of .canvas that isnt the image. closest must be a
        // .placement. add all offsets until .canvas. .canvas is positioned
        // relative so we know it will be an offsetParent
        do {
          x += target.offsetLeft;
          y += target.offsetTop;
        } while (
          target.offsetParent !== this.canvasRef &&
          target.offsetParent instanceof HTMLElement &&
          (target = target.offsetParent)
        );

        // target is .placement now
        // adjust for the -50% translation of .placement
        x -= target.offsetWidth / 2;
        y -= target.offsetHeight / 2;
      }

      // position has pixel values relative to canvas top/left, so we need to
      // translate by `(scale - 1) / 2 * canvas` so they are relative to
      // image top/left
      x += ((this.scale - 1) / 2) * this.canvasRef.offsetWidth;
      y += ((this.scale - 1) / 2) * this.canvasRef.offsetHeight;
    }

    // subtract the grab position and convert the pixel values into relative
    // positioning values
    const position = {
      x: Math.min(Math.max(-0.25, (x - grabPosition.x) / this.scale / this.canvasRef.offsetWidth), 1.25),
      y: Math.min(Math.max(-0.25, (y - grabPosition.y) / this.scale / this.canvasRef.offsetHeight), 1.25),
    };

    this.$emit('placement-dropped', { ...placement, position });
  }
}
