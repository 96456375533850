
import { VNode } from 'vue';
import VueSelect from 'vue-select';
import { Component, Vue } from 'vue-property-decorator';

@Component({ inheritAttrs: false })
export default class Select extends Vue {
  public render(): VNode {
    return this.$createElement(VueSelect, {
      ...(this.$vnode.data || {}),
      on: this.$listeners,
      scopedSlots: this.$scopedSlots,
    });
  }
}
