





















import { BooleanProp, StringProp, FunctionProp, ArrayProp } from '@/util/prop-decorators';
import moment, { Moment, MomentInput } from 'moment';
import { Component, Vue, Model, Watch } from 'vue-property-decorator';
import Datepicker from 'vuejs-datepicker';
import { de } from 'vuejs-datepicker/dist/locale';
import CalendarIcon from './icons/calendar.svg?vue';
import ClearIcon from './icons/clear.svg?vue';
import { Input, DatePreset } from './model';

@Component({ components: { CalendarIcon, Datepicker, ClearIcon }, inheritAttrs: false })
export default class InputDate extends Vue implements Input {
  @Model('update')
  private readonly value?: MomentInput;

  @FunctionProp()
  private readonly format?: (date: Moment) => string;

  @BooleanProp()
  private readonly disabled!: boolean;

  @BooleanProp()
  private readonly error!: boolean;

  @StringProp()
  private readonly placeholder?: string;

  @ArrayProp(() => [])
  private readonly presets!: DatePreset[];

  @BooleanProp()
  private readonly nonClearable!: boolean;

  private open = false;

  public pristine = true;

  private readonly de = de;

  private componentKey = 0;

  @Watch('$attrs')
  private onChangeAttrs(): void {
    // dinamically $attrs are not working on vuejs-datepicker and we have to trigger the re-render
    this.componentKey++;
  }

  private get momentDate(): Moment | undefined {
    return this.value === undefined || this.value === null ? undefined : moment(this.value).startOf('day');
  }

  private get model(): Date | undefined {
    return this.momentDate === undefined || !this.momentDate.isValid() ? undefined : this.momentDate.toDate();
  }

  private set model(value: Date | undefined) {
    this.open = false;
    this.$emit('update', value);
  }

  public get normalizedValue(): Date | undefined {
    return this.model;
  }

  public get empty(): boolean {
    return this.normalizedValue === undefined;
  }

  private get formattedDate(): string | undefined {
    return this.momentDate === undefined
      ? undefined
      : this.format
      ? this.format(this.momentDate)
      : this.momentDate.format('L');
  }

  @Watch('disabled')
  private closeWhenDisabled(): void {
    if (this.disabled) {
      this.open = false;
    }
  }

  private show(): void {
    this.open = true;
    this.pristine = false;
  }

  private clear(): void {
    this.open = false;
    this.model = undefined;
    this.pristine = false;
  }

  private onPresetClick(date: DatePreset['date']): void {
    this.$emit('update', date(this.now)?.toDate());
  }

  private now(): Moment {
    return moment();
  }
}
