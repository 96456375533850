import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import { Exporter } from './model';

export const csv: Exporter = async ({ rows, columns, filename, includeColumnNames }) => {
  const data: string[][] = [];

  if (includeColumnNames) {
    data.push(columns.map(({ name }) => name));
  }

  data.push(columns.map(({ label }) => label));

  const descriptionCount = columns.reduce((a, { descriptions }) => Math.max(a, descriptions.length), 0);
  for (let i = 0; i < descriptionCount; i++) {
    data.push(columns.map(({ descriptions }) => descriptions[i] ?? ''));
  }

  data.push(columns.map(() => ''));

  for await (const row of rows()) {
    data.push(columns.map((column) => column.formatText(row, column, column.accessor(row))));
  }

  const csv = unparse(data);
  // including a BOM which is pretty much useless in UTF-8, but makes common
  // downstream applications like excel to use UTF-8
  saveAs(new Blob([`\ufeff${csv}`], { type: 'application/csv; charset=utf-8' }), filename);
};
