
import { ArrayProp } from '@/util/prop-decorators';
import { ChartPoint, ChartData, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Doughnut } from 'vue-chartjs';

@Component({ extends: Doughnut })
export default class DoughnutChart extends Mixins(Doughnut) {
  @ArrayProp(true)
  private chartPoints!: ChartPoint[];

  @ArrayProp(true)
  private colors!: string[];

  @Watch('chartPoints')
  private dataPoints(): void {
    return this.renderChart(this.chartData, this.chartOptions);
  }

  private get chartData(): ChartData {
    return {
      datasets: [
        {
          data: this.chartPoints,
          backgroundColor: this.colors,
          borderWidth: 0,
        },
      ],
    };
  }

  private get chartOptions(): ChartOptions {
    return {
      responsive: true,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        yAxes: [
          {
            display: false,
          },
        ],
        xAxes: [
          {
            display: false,
          },
        ],
      },
    };
  }

  private mounted(): void {
    if (this.chartPoints.length < 1) {
      return;
    }

    this.addPlugin([ChartDataLabels]);
    this.renderChart(this.chartData, this.chartOptions);
  }
}
