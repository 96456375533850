















import FlexChildStyle from '@/features/ui/mixins/flex-child-style';
import { BooleanProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class AccordionCell extends Mixins(FlexChildStyle) {
  @BooleanProp()
  private readonly visible!: boolean;

  public readonly $refs!: { inner: HTMLElement };

  private applyHeight(el: HTMLElement): void {
    el.style.height = `${this.$refs.inner.clientHeight + 20}px`;
  }

  private resetHeight(el: HTMLElement): void {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        el.style.height = '';
      });
    });
  }
}
