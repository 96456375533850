






import { Component, Vue } from 'vue-property-decorator';
import UiClickable from '@/components/clickables/Clickable.global.vue';

@Component({ components: { UiClickable } })
export default class BreadcrumbItem extends Vue {}
