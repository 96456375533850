















import TextInput from '@/components/form/TextInput.vue';
import { Component, Vue } from 'vue-property-decorator';
import BindingWizard from './BindingWizard.vue';
import StepControls from './StepControls.vue';
import StepHint from './StepHint.vue';
import StepLabel from './StepLabel.vue';

@Component({
  components: { StepControls, StepHint, StepLabel, TextInput },
})
export default class Step1 extends Vue {
  public readonly $parent!: BindingWizard;

  private displayErrors = false;

  private get valid(): boolean {
    return this.nameValid;
  }

  private get nameValid(): boolean {
    return this.$parent.config.name.length > 0;
  }

  private onNext(): void {
    if (!this.valid) {
      this.displayErrors = true;

      return;
    }

    this.$parent.step++;
  }
}
