













import { Component, Model, Vue } from 'vue-property-decorator';
import { BooleanProp, OptionalProp } from '@/util/prop-decorators';

@Component({ inheritAttrs: false })
export default class RadioButton extends Vue {
  @Model('update')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private value!: any;

  @OptionalProp()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private checkedValue!: any;

  @BooleanProp()
  private replaced!: boolean;

  private get checked(): boolean {
    return this.value === this.checkedValue;
  }
}
