













import { Component, Model, Vue } from 'vue-property-decorator';
import { StringProp } from '@/util/prop-decorators';
import RadioButton from './RadioButton.vue';

@Component({ components: { RadioButton }, inheritAttrs: false })
export default class EmblemRadioButton extends Vue {
  @Model('update')
  private value!: unknown;

  @StringProp(true)
  private src!: string;

  @StringProp()
  private label?: string;

  private hover = false;

  private mouseEnter(): void {
    this.hover = true;
    this.$emit('mouseenter');
  }

  private mouseLeave(): void {
    this.hover = false;
    this.$emit('mouseleave');
  }
}
