
import { BModal } from 'bootstrap-vue';
import { concat, filter } from 'lodash';
import { VNode } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { BModal },
  inheritAttrs: false,
})
export default class Modal extends Vue {
  private shownPromise?: Promise<void>;
  private hiddenPromise?: Promise<void>;
  private resolveShown!: () => void;
  private resolveHidden!: () => void;

  public shown(): Promise<void> {
    return this.shownPromise || Promise.resolve();
  }

  public hidden(): Promise<void> {
    return this.hiddenPromise || Promise.resolve();
  }

  private onShown(): void {
    this.resetHidden();
    this.resolveShown();
  }

  private onHidden(): void {
    this.resetShown();
    this.resolveHidden();
    this.$emit('hide');
  }

  private resetShown(): void {
    this.shownPromise = new Promise((resolve) => void (this.resolveShown = resolve));
  }

  private resetHidden(): void {
    this.hiddenPromise = new Promise((resolve) => void (this.resolveHidden = resolve));
  }

  private created(): void {
    this.resetShown();
    this.resetHidden();

    this.$attrs.visible ? this.resolveShown() : this.resolveHidden();
  }

  public render(): VNode {
    const data = this.$vnode.data || {};

    return this.$createElement(BModal, {
      class: `modal ${data.staticClass}`,
      attrs: {
        hideHeader: true,
        hideFooter: true,
        ...this.$attrs,
      },
      on: {
        ...this.$listeners,
        shown: filter(concat(this.onShown, this.$listeners.shown)),
        hidden: filter(concat(this.onHidden, this.$listeners.hidden)),
      },
      scopedSlots: this.$scopedSlots,
    });
  }
}
