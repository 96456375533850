












import { Option } from '@/features/ui/inputs/model';
import { Component, Model, Vue } from 'vue-property-decorator';

export enum PAGINATION_PER_PAGE {
  ONE_HUNDRED = '100',
  FIFTY = '50',
  TWENTY_FIVE = '25',
  TEN = '10',
}

@Component
export default class PageSize extends Vue {
  @Model('update', { type: [String, Number], required: true })
  public value!: number;

  private get pageSizeOptions(): Option[] {
    return Object.values(PAGINATION_PER_PAGE).map((value) => ({ label: String(value), value: Number(value) }));
  }
}
