




















import { RequiredProp } from '@/util/prop-decorators';
import { Component, Inject, Vue } from 'vue-property-decorator';
import InputTreeSelect from './InputTreeSelect.global.vue';
import { INPUT_TREE_SELECT } from './model';
import NodeList from './NodeList.vue';

@Component
export default class Node<T = unknown> extends Vue {
  @RequiredProp()
  private node!: T;

  // use as dynamic component to handle recursive component nesting
  private nodeList = NodeList;

  @Inject(INPUT_TREE_SELECT)
  private input!: InputTreeSelect<T>;

  private get selectable(): boolean {
    return this.input.tree.isNodeSelectable(this.node);
  }

  private get indeterminate(): boolean {
    return this.input.hasSelectedDescendants(this.node);
  }

  private get implicit(): boolean {
    return this.input.implicit && this.input.hasSelectedAncestors(this.node);
  }

  private get label(): string {
    return this.input.tree.labelOf(this.node);
  }

  private get open(): boolean {
    return this.input.isNodeOpen(this.node);
  }

  private get children(): T[] {
    return this.input.tree.childrenOf(this.node).sort((a, b) => this.input.tree.compare(a, b));
  }

  private toggleOpenness(): void {
    this.input.toggleOpenness(this.node);
  }
}
