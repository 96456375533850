






import { Component, Vue } from 'vue-property-decorator';
import IotGatewayContent from './IotGatewayContent.vue';

@Component({
  components: { IotGatewayContent },
  inheritAttrs: false,
})
export default class IotGatewayPanel extends Vue {}
