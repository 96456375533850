




import { StringProp } from '@/util/prop-decorators';
import { Component, Model, Vue } from 'vue-property-decorator';

import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import { TimepickerModel } from './model';
@Component({ components: { VueTimepicker } })
export default class InputTimepicker extends Vue {
  @Model('update')
  private readonly value!: TimepickerModel | string;

  @StringProp()
  private readonly placeholder?: string;

  @StringProp()
  private readonly format?: string;

  private get model(): TimepickerModel | string {
    return this.value;
  }

  private set model(value: TimepickerModel | string) {
    if (typeof value !== 'string') {
      const { HH, mm } = value;
      this.$emit('update', `${HH !== '' ? HH : '00'}:${mm !== '' ? mm : '00'}`);
    } else {
      const [HH, mm] = value.split(':');
      if (mm === '') {
        value = `${HH}:00`;
      }
      this.$emit('update', value);
    }
  }
}
