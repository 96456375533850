









import { Component, Vue } from 'vue-property-decorator';
import UiClickable from '@/components/clickables/Clickable.global.vue';
import { EnumProp, BooleanProp } from '@/util/prop-decorators';

@Component({ components: { UiClickable }, inheritAttrs: false })
export default class SelectableButton extends Vue {
  @BooleanProp()
  private selected!: boolean;

  @EnumProp(true, 'RED', 'GREEN', 'BLUE')
  private color!: 'RED' | 'GREEN' | 'BLUE';
}
