import { EChartsOption, EChartsType } from 'echarts';
import { TooltipComponentOption } from 'echarts/components';

export type Unified<T> = Exclude<T, T[]>;
export type TooltipFormatterCallback = Exclude<NonNullable<TooltipComponentOption['formatter']>, string>;
export type TooltipFormatterParams = Parameters<TooltipFormatterCallback>[0];
export type SingleTooltipFormatterParams = Unified<TooltipFormatterParams>;
export type MultipleTooltipFormatterParams = SingleTooltipFormatterParams[];

export type Chart = EChartsType;

export type ChartOptions = EChartsOption;

export const CHART_SERIES_COLOR_PALLETE = Object.freeze([
  '#FF0000',
  '#0000FF',
  '#008080',
  '#FFA500',
  '#00FFFF',
  '#FFFF00',
  '#8A2BE2',
  '#008000',
  '#A9A9A9',
  '#B8860B',
  '#000000',
]);
