
















import { Debounce } from '@/util/debounce-decorator';
import { isDef } from '@/util/lang';
import NextTick from '@/util/next-tick-decorator';
import { BooleanProp, ObjectProp } from '@/util/prop-decorators';
import { Map as OlMap, View, Collection, MapBrowserEvent } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { defaults as defaultInteractions, MouseWheelZoom, DragPan, Interaction } from 'ol/interaction';
import { platformModifierKeyOnly, mouseOnly } from 'ol/events/condition';
import { Extent, boundingExtent } from 'ol/extent';
import { Tile as TileLayer } from 'ol/layer';
import { OSM } from 'ol/source';
import { FitOptions } from 'ol/View';
import { Component, Provide, Vue } from 'vue-property-decorator';
import { GET_MAP, LOCATION_GERMANY, REFIT } from './model';

@Component({
  data() {
    return { wheel: undefined, touch: undefined };
  },
})
export default class Map extends Vue {
  @BooleanProp()
  private readonly static!: boolean;
  @ObjectProp()
  private readonly refitOptions?: FitOptions;

  // non-reactivity intended
  public map!: OlMap;
  public fitting!: boolean;
  public interacted!: boolean;

  public readonly $refs!: { map: HTMLElement };

  public wheel?: ReturnType<typeof setTimeout>;
  public touch?: ReturnType<typeof setTimeout>;

  @Provide(GET_MAP)
  private getMap(): OlMap {
    return this.map;
  }

  @Provide(REFIT)
  @Debounce(200)
  @NextTick(true)
  private refit(force = false): void {
    if (!this.map) {
      return;
    }
    if (!force && this.interacted) {
      return;
    }

    const extent = boundingExtent(
      this.map
        .getOverlays()
        .getArray()
        .map((overlay) => overlay.getPosition())
        .filter(isDef),
    );

    this.fit(extent, { maxZoom: 16, padding: [60, 60, 60, 60], ...this.refitOptions });
  }

  private created(): void {
    this.fitting = false;
    this.interacted = false;

    const controls = this.static ? [] : defaultControls({ attribution: false });
    const interactions = this.createInteractions();

    const source = new OSM();
    const layers = [new TileLayer({ source })];
    const view = new View({ center: LOCATION_GERMANY, zoom: 5, enableRotation: false });

    this.map = new OlMap({ controls, interactions, layers, view });

    const onViewChange = (): void => {
      if (this.fitting) {
        return;
      }

      this.interacted = true;
      view.un('change', onViewChange);
    };
    view.on('change', onViewChange);
  }

  private mounted(): void {
    this.map.setTarget(this.$refs.map);
  }

  private destroyed(): void {
    const { $el, map } = this;
    this.map = undefined as unknown as OlMap;

    if (!document.body.contains($el)) {
      return map.setTarget(undefined);
    }

    new MutationObserver((mutations, observer) => {
      for (const { removedNodes } of mutations) {
        for (const node of removedNodes) {
          if (node.contains($el)) {
            observer.disconnect();

            return map.setTarget(undefined);
          }
        }
      }
    }).observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  private onWheel(event: WheelEvent): void {
    if (this.static) {
      return;
    }
    if (platformModifierKeyOnly(new MapBrowserEvent('wheel', this.map, event))) {
      return;
    }

    if (this.wheel !== undefined) {
      clearTimeout(this.wheel);
    }

    this.wheel = setTimeout(() => void (this.wheel = undefined), 1000);
  }

  private onTouchmove(event: TouchEvent): void {
    if (this.static) {
      return;
    }
    if (event.targetTouches.length === 2) {
      return;
    }

    if (this.touch !== undefined) {
      clearTimeout(this.touch);
    }

    this.touch = setTimeout(() => void (this.touch = undefined), 1000);
  }

  private createInteractions(): Collection<Interaction> {
    if (this.static) {
      return new Collection<Interaction>();
    }

    return defaultInteractions({ dragPan: false, mouseWheelZoom: false }).extend([
      new DragPan({
        condition(event): boolean {
          return this.getPointerCount() === 2 || mouseOnly(event);
        },
      }),
      new MouseWheelZoom({ condition: platformModifierKeyOnly }),
    ]);
  }

  private fit(extent: Extent, options?: FitOptions): void {
    this.fitting = true;
    const { callback = undefined } = options || {};

    this.map.getView().fit(extent, {
      ...options,
      callback: (...args) => {
        this.fitting = false;

        if (callback) {
          callback(...args);
        }
      },
    });
  }
}
