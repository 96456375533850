





import { toLength } from '@/util/css-length';
import { LengthProp, BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Grid extends Vue {
  @LengthProp('auto')
  private readonly minColumnWidth!: string | number;

  @LengthProp('auto')
  private readonly rowHeight!: string | number;

  @BooleanProp()
  private readonly fit!: boolean;

  @LengthProp(20)
  private readonly columnGap!: string | number;

  @LengthProp(20)
  private readonly rowGap!: string | number;

  private get style(): Record<string, string | undefined> {
    const mode = this.fit ? 'auto-fit' : 'auto-fill';
    const min = toLength(this.minColumnWidth);

    return {
      'grid-template-columns': `repeat(${mode}, minmax(${min}, 1fr))`,
      'grid-auto-rows': toLength(this.rowHeight),
      'column-gap': toLength(this.columnGap),
      'row-gap': toLength(this.rowGap),
    };
  }
}
