










import { Component, Vue } from 'vue-property-decorator';
import UiClickable from '@/components/clickables/Clickable.global.vue';
import UiWizardButton from '@/components/clickables/WizardButton.global.vue';
import ArrowNext from './icons/arrow-next.svg?vue';
import { BooleanProp } from '@/util/prop-decorators';

@Component({
  components: { ArrowNext, UiClickable, UiWizardButton },
})
export default class StepControls extends Vue {
  @BooleanProp()
  private inactive!: boolean;
  @BooleanProp()
  private busy!: boolean;
}
