

























import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { SpotRole } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import BindingVendorApiConfig from './binding-vendor-api-config/BindingVendorApiConfig.vue';
import BindingWizard from './BindingWizard.vue';
import { BindingConfig, Option } from './model';
import StepControls from './StepControls.vue';
import StepHint from './StepHint.vue';
import StepLabel from './StepLabel.vue';

// FIXME 16hex is too short for possible key lengths of AES:
// 128bit (32hex)
// 192bit (48hex)
// 256bit (64hex)
// talk to tarent what the actual encryption standard in use is
const AES_FORMAT = /^([a-z0-9]{16}){2,4}$/i;

@Component({
  components: { StepControls, StepHint, StepLabel, BindingVendorApiConfig },
})
export default class Step4 extends Vue {
  @RootAction
  public readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  public readonly $parent!: BindingWizard;

  private displayErrors = false;
  private errorMessage = '';

  private get valid(): boolean {
    return this.serialValid && this.encryptionKeyValid;
  }

  private get serialValid(): boolean {
    return this.$parent.config.serial.length > 0;
  }

  private get encryptionKeyValid(): boolean {
    const key = this.$parent.config.encryptionKey;

    return !key || AES_FORMAT.test(key);
  }

  private get selectedRoleOption(): Option {
    for (const option of this.$parent.roleOptions) {
      if (option.value === this.$parent.config.role) {
        return option;
      }
    }

    return { value: '', label: '' };
  }

  private get selectedManufacturerOption(): Option {
    for (const option of this.$parent.manufacturerOptions) {
      if (option.value === this.$parent.config.manufacturer) {
        return option;
      }
    }

    return { value: '', label: '' };
  }

  private async complete(configData: Partial<BindingConfig>): Promise<void> {
    this.$parent.config = Object.assign(this.$parent.config, configData);
    if (!this.serialValid) {
      throw new Error('Keine Serienummer');
    }

    if (!this.encryptionKeyValid) {
      throw new Error('Ungültiges Format für AES Key!');
    }

    const result = await this.$parent.complete();

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Gerät hinzugefügt!', class: 'success' }],
    });

    return result;
  }

  private async mounted(): Promise<void> {
    if (this.$parent.config.role === SpotRole.OUTSIDE_TEMPERATURE) {
      this.$parent.close();

      await this.$parent.complete();

      void this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Gerät hinzugefügt!', class: 'success' }],
      });
    }
  }
}
