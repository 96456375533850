






















import { ArrayProp, BooleanProp } from '@/util/prop-decorators';
import { isArray } from 'lodash';
import { Component, Vue, Model } from 'vue-property-decorator';
import { Input, Option } from './model';

@Component
export default class InputCheckboxList extends Vue implements Input {
  @Model('update')
  private value!: unknown;

  @ArrayProp(() => [])
  private options!: Option[];

  @BooleanProp()
  private all!: boolean;

  @BooleanProp()
  private inlineOptions!: boolean;

  public readonly $refs!: { checkboxes: (Vue & Input)[] };

  private get model(): unknown[] {
    return isArray(this.value) ? this.value : [];
  }

  private set model(value: unknown[]) {
    this.$emit('update', value);
  }

  public get normalizedValue(): unknown[] {
    return this.model;
  }

  public get empty(): boolean {
    return this.normalizedValue.length === 0;
  }

  public get pristine(): boolean {
    return this.$refs.checkboxes.every(({ pristine }) => pristine);
  }
}
