















import { BooleanProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Overlay extends Vue {
  @StringProp(true)
  private readonly title!: string;

  @BooleanProp()
  public shown!: boolean;
}
