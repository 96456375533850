







import { BooleanProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Link extends Vue {
  @StringProp()
  private readonly icon?: string;

  @BooleanProp()
  private readonly iconBefore!: boolean;
}
