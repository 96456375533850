










import { EnumProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import MasterGatewayIcon from './master-gateway.svg?vue';

@Component({ components: { MasterGatewayIcon } })
export default class MasterGateway extends Vue {
  @EnumProp('GRAY', 'GREEN', 'RED')
  private readonly iconColor!: 'GRAY' | 'GREEN' | 'RED';
}
