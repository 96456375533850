


















import { ObjectProp, EnumProp, NumberProp, OptionalProp } from '@/util/prop-decorators';
import { Component as ComponentType } from 'vue';
import { Component, Vue, Model } from 'vue-property-decorator';

@Component({ inheritAttrs: false })
export default class Tooltip extends Vue {
  @Model('update:open', { type: Boolean, default: false })
  private readonly open!: boolean;

  @EnumProp('top', 'bottom', 'left', 'right')
  private readonly placement!: string;

  @EnumProp('hover', 'manual', 'click')
  private readonly trigger!: string;

  @NumberProp(0)
  private readonly showDelay!: number;

  @NumberProp(300)
  private readonly hideDelay!: number;

  @OptionalProp()
  private readonly contentComponent?: ComponentType | string;

  @ObjectProp()
  private readonly contentProps?: Record<string, unknown>;
}
