





import { IntegerProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Headline extends Vue {
  @IntegerProp(3, 1, 7)
  private readonly grade?: string;

  private get headlineTag(): string {
    return `h${this.grade}`;
  }
}
