








import { Component, Vue } from 'vue-property-decorator';
import Button from '@/components/clickables/Button.global.vue';
import { StringProp } from '@/util/prop-decorators';
import triangle from '@/assets/images/triangle.svg';

@Component({
  components: {
    'ui-button': Button,
  },
  inheritAttrs: false,
})
export default class ApartmentListItem extends Vue {
  @StringProp(true)
  public buttonLabel!: string;

  private triangle = triangle;
}
