





import { ArrayProp, RequiredProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import CheckBox from './CheckBox.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Value = any;

@Component({ components: { CheckBox }, model: { prop: 'selection' }, inheritAttrs: false })
export default class ListCheckBox extends Vue {
  @ArrayProp()
  private selection!: Value[];

  @RequiredProp()
  private value!: Value;

  private get checked(): boolean {
    return this.selection.includes(this.value);
  }

  private set checked(value: boolean) {
    if (this.checked === value) {
      return;
    }

    const selection = value ? [...this.selection, this.value] : this.selection.filter((value) => value !== this.value);

    this.$emit('input', selection);
  }
}
