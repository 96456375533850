













import { MomentInput } from 'moment';
import { RequiredProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import window from './window.svg';
import DevicePanel from '@/components/sensor/DevicePanel.vue';

@Component({
  components: {
    DevicePanel,
  },
})
export default class WindowSensorPanel extends Vue {
  @RequiredProp()
  private lastActivityDate!: Exclude<MomentInput, void>;

  private window = window;
}
