













import { ArrayProp, FunctionProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

type Comparator<T> = (a: T, b: T) => number;

@Component
export default class InlineList<T = unknown> extends Vue {
  @ArrayProp(() => [])
  private readonly items!: T[];

  @StringProp('–')
  private readonly emptyMessage!: string;

  @FunctionProp()
  private readonly comparator?: Comparator<T>;

  private get tag(): string {
    return this.comparator === undefined ? 'ul' : 'ol';
  }

  private get orderedItems(): T[] {
    return this.comparator === undefined ? this.items : [...this.items].sort(this.comparator);
  }
}
