






















import EmblemRadioButton from '@/components/form/EmblemRadioButton.vue';
import { SpotRole } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import BindingWizard from './BindingWizard.vue';
import StepControls from './StepControls.vue';
import StepHint from './StepHint.vue';
import StepLabel from './StepLabel.vue';

@Component({
  components: { EmblemRadioButton, StepControls, StepHint, StepLabel },
  data() {
    return {
      hoverManufacturerLabel: undefined,
    };
  },
})
export default class Step3 extends Vue {
  public readonly $parent!: BindingWizard;

  private displayErrors = false;
  private hoverManufacturerLabel?: string;

  private get valid(): boolean {
    return this.manufacturerValid;
  }

  private get manufacturerValid(): boolean {
    return this.$parent.config.manufacturer.length > 0;
  }

  private get manufacturerLabel(): string {
    for (const option of this.$parent.manufacturerOptions) {
      if (option.value === this.$parent.config.manufacturer) {
        return option.label;
      }
    }

    return '';
  }

  private onNext(): void {
    if (!this.valid) {
      this.displayErrors = true;

      return;
    }

    this.$parent.step++;
  }

  private async mounted(): Promise<void> {
    if (this.$parent.config.role === SpotRole.OUTSIDE_TEMPERATURE) {
      this.$parent.config.manufacturer = '';
      this.$parent.step++;
    }
  }
}
