











import { Component, Vue } from 'vue-property-decorator';
import { ArrayProp } from '@/util/prop-decorators';
import { Point } from './model';

@Component
export default class StackedHorizontalChart extends Vue {
  @ArrayProp(() => [])
  private points!: Point[];
}
