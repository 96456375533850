import { render, staticRenderFns } from "./SelectableButton.global.vue?vue&type=template&id=5b66060d&scoped=true&lang=pug&"
import script from "./SelectableButton.global.vue?vue&type=script&lang=ts&"
export * from "./SelectableButton.global.vue?vue&type=script&lang=ts&"
import style0 from "./SelectableButton.global.vue?vue&type=style&index=0&id=5b66060d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b66060d",
  null
  
)

export default component.exports