












import { Component, Vue } from 'vue-property-decorator';
import UiClickable from '@/components/clickables/Clickable.global.vue';
import Spinner from '@/components/clickables/Spinner.vue';
import { BooleanProp, EnumProp } from '@/util/prop-decorators';

@Component({ components: { UiClickable, Spinner }, inheritAttrs: false })
export default class FlatButton extends Vue {
  @BooleanProp()
  private readonly fixedWidth!: boolean;
  @BooleanProp()
  private readonly busy!: boolean;
  @EnumProp('BLUE', 'RED', 'GREEN')
  private readonly color!: 'RED' | 'GREEN' | 'BLUE';
}
